import React, { useState, useEffect } from 'react';
import './SiteVisitCalling.css';
import { useParams, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import NaviMain from '../NavigationMain'
import Foot from '../footer';
import Switch from '@mui/material/Switch';


const SiteVisitCalling = () => {
  const { cid} = useParams();

  const [visitGetDate, setVisitGetDate] = useState('');
  const [visitGet, setVisitGet] = useState('');
  const [fVisitCallRemark, setFVisitCallRemark] = useState('');
  const [sVisitCallRemark, setSVisitCallRemark] = useState('');
  const [tVisitCallRemark, setTVisitCallRemark] = useState('');
  const [fourVisitCallRemark, setSFourVisitCallRemark] = useState('');
  const [fiveVisitCallRemark, setFiveVisitCallRemark] = useState('');

  const [fVisitCallDate, setFVisitCallDate] = useState('');
  const [sVisitCallDate, setSVisitCallDate] = useState('');
  const [tVisitCallDate, setTVisitCallDate] = useState('');
  const [fourVisitCallDate, setFourVisitCallDate] = useState('');
  const [fiveVisitCallDate, setfiveVisitCallDate] = useState('');

  const [visitedPersons, setVisitedPersons] = useState('');
  const [visitedDate, setVisitedDate] = useState('');

  const [isServiceAdded, setIsServiceAdded] = useState({
      fs: false,
      ss: false,
      ts: false,
      fours: false,
      fives: false,
  });

  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

    const userEmail = sessionStorage.getItem('userEmail');
  
    useEffect(() => {
      if (!userEmail) {
        navigate('/login');
      } else {
        fetchServiceData();
      }
    }, [cid, userEmail, navigate]);

    const fetchServiceData = () => {
        if (cid) {
            Axios.get(process.env.REACT_APP_HOST_IP + `/api/sitevisit?cid=${cid}`)
                .then((response) => {
                    if (response.data.response) {
                        const { visitGet, visitGetDate, fVisitCallRemark, sVisitCallRemark, tVisitCallRemark, fourVisitCallRemark, fiveVisitCallRemark, fVisitCallDate, sVisitCallDate, tVisitCallDate, fourVisitCallDate, fiveVisitCallDate, visitedPersons, visitedDate } = response.data.response;
                        setVisitGet(visitGet || '');
                        setVisitGetDate(visitGetDate || '');
                        setFVisitCallRemark(fVisitCallRemark || '');
                        setSVisitCallRemark(sVisitCallRemark || '');
                        setTVisitCallRemark(tVisitCallRemark || '');
                        setSFourVisitCallRemark(fourVisitCallRemark || '');
                        setFiveVisitCallRemark(fiveVisitCallRemark || '');

                        setFVisitCallDate(fVisitCallDate || '');
                        setSVisitCallDate(sVisitCallDate || '');
                        setTVisitCallDate(tVisitCallDate || '');
                        setFourVisitCallDate(fourVisitCallDate || '');
                        setfiveVisitCallDate(fiveVisitCallDate || '');

                        setVisitedPersons(visitedPersons || '');
                        setVisitedDate(visitedDate || '');
                        // Disable button if there's an existing date
                        setIsServiceAdded({
                            fs: !!fVisitCallDate,
                            ss: !!sVisitCallDate,
                            ts: !!tVisitCallDate,
                            fours: !!fourVisitCallDate,
                            fives: !!fiveVisitCallDate
                        });

                        console.log(visitedPersons)
                    }
                })
                .catch((error) => {
                    console.error('Error fetching client data:', error);
                });
        }
    };

    const updateFirstCall = (type) => {
    
      const payload = {
          cid: cid,
          fVisitCallRemark: fVisitCallRemark,
          fVisitCallDate: Date()
      };

      console.log(payload);
  
      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updatesitevisit', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
  };

    const updateService = (type) => {
    
        const payload = {
            cid: cid,
            sVisitCallRemark: sVisitCallRemark,
            sVisitCallDate: Date(),
        };

        console.log(payload);
    
        Axios.post(process.env.REACT_APP_HOST_IP + '/api/updatesitevisit', payload)
            .then((response) => {
                setAlert({
                    type: 'success',
                    message: 'Service updated successfully!',
                  });
                setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
                fetchServiceData(); // Fetch and update the data after adding service
            })
            .catch((error) => {
                setAlert({
                    type: 'error',
                    message: 'Service added Unsucessfull..! Try again',
                  });
                console.error('Axios Error: ', error);
            });
    };

    const UpdateThirdCall = (type) => { 
      const payload = {
          cid: cid,
          tVisitCallRemark: tVisitCallRemark,
          tVisitCallDate: Date()
      };

      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updatesitevisit', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
  };



  const UpdateFourthCall = (type) => { 
    const payload = {
        cid: cid,
        fourVisitCallRemark: fourVisitCallRemark,
        fourVisitCallDate: Date()
    };
    
    Axios.post(process.env.REACT_APP_HOST_IP + '/api/updatesitevisit', payload)
        .then((response) => {
            setAlert({
                type: 'success',
                message: 'Service updated successfully!',
              });
            setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
            fetchServiceData(); // Fetch and update the data after adding service
        })
        .catch((error) => {
            setAlert({
                type: 'error',
                message: 'Service added Unsucessfull..! Try again',
              });
            console.error('Axios Error: ', error);
        });
  };





    const UpdateFifthCall = (type) => { 
      const payload = {
          cid: cid,
          fiveVisitCallRemark: fiveVisitCallRemark,
          fiveVisitCallDate: Date()
      };
      
      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updatesitevisit', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
    };


    const AddVisitedPersons = (type) => { 
      const payload = {
          cid: cid,
          visitedPersons: visitedPersons,
          visitedDate: visitedDate
      };
      
      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updatesitevisit', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
    };


    const isSectionReadOnly = (type) => !!isServiceAdded[type];
    const getInputType = (dateValue) => (dateValue ? 'text' : 'date');


    const handleVisitToggle = async (cid, currentStatus) => {
      const newStatus = currentStatus === 'Yes' ? 'No' : 'Yes';
      try {
        // Make an API call to update the sendPDF status
        const payload = {
          cid: cid,
          visitGet: newStatus,
          visitGetDate: Date()
        }
        console.log(payload)
        await Axios.post(`${process.env.REACT_APP_HOST_IP}/api/updatesitevisit`,payload);
  
        // Update the local state
        setVisitGet(newStatus);
      } catch (error) {
        console.error('Error updating sendPDF status:', error);
        // Optionally, show an error message to the user
      }
    };


    const isEditable = (callDate) => {
      return !callDate || (Date.now() - new Date(callDate).getTime() < 24 * 60 * 60 * 1000); // Check if call date is less than 24 hours old
    };

    return (
      <div class="container">
          <div class="menu-body">
              <NaviMain />
          </div>
      
      <div className="service-form-container">
          <h2>Site Visit Calling</h2>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Switch
              checked={visitGet === 'Yes'}
              onChange={() => handleVisitToggle(cid, visitGet)}
              color="primary"
            />
            <span style={{ marginLeft: '10px' }}>
              {visitGet === 'Yes' ? 'Site Visit Obtained' : 'Site Visit Pending'}
            </span>
          </div>

          {/* Add this new section to display the clearance get date */}
          {visitGetDate && (
            <div style={{ marginBottom: '20px' }}>
              <strong>Site Visit Get Date:</strong> {new Date(visitGetDate).toLocaleString()}
            </div>
          )}

          <form>
              {/* Client ID Section */}
              <div className="form-section">
                  <label htmlFor="clientId">Client ID</label>
                  <input 
                      type="text" 
                      id="clientId" 
                      name="clientId" 
                      className="input-field" 
                      value={cid}
                      readOnly 
                  />
              </div>

              {/* First Service Section */}
              <div className={`form-section ${isSectionReadOnly('fs') ? 'read-only' : ''}`}>
                  <h3>First Site Visit Call</h3>
                  
                  <label htmlFor="firstServiceRemark">1st Call Remark</label>
                  <textarea 
                      id="firstServiceRemark" 
                      name="firstServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={fVisitCallRemark}
                      onChange={(e) => setFVisitCallRemark(e.target.value)}
                      readOnly={!isEditable(fVisitCallDate)}
                  ></textarea>
                  {fVisitCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(fVisitCallDate).toLocaleString()}
                    </div>
                  )}
                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => updateFirstCall('fs')}
                      disabled={!isEditable(fVisitCallDate)}
                  >
                      Add 1st Call
                  </button>
              </div>



              {/* Second Service Section */}
              <div className={`form-section ${isSectionReadOnly('ss') ? 'read-only' : ''}`}>
                  <h3>Second Site Visit Call</h3>
                  
                  <label htmlFor="secondServiceRemark">2nd Call Remark</label>
                  <textarea 
                      id="secondServiceRemark" 
                      name="secondServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={sVisitCallRemark}
                      onChange={(e) => setSVisitCallRemark(e.target.value)}
                      readOnly={!isEditable(sVisitCallDate)}
                  ></textarea>

                  {sVisitCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(sVisitCallDate).toLocaleString()}
                    </div>
                  )}
                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => updateService('ss')}
                      disabled={!isEditable(sVisitCallDate)}
                  >
                      Add 2nd Call  
                  </button>
              </div>


              {/* Third Service Section */}
              <div className={`form-section ${isSectionReadOnly('ts') ? 'read-only' : ''}`}>
                  <h3>Third Site Visit Call</h3>

                  <label htmlFor="thirdServiceRemark">3rd Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={tVisitCallRemark}
                      onChange={(e) => setTVisitCallRemark(e.target.value)}
                      readOnly={!isEditable(tVisitCallDate)}
                  ></textarea>
                  {tVisitCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(tVisitCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateThirdCall('ts')}
                      disabled={!isEditable(tVisitCallDate)}
                  >
                      Add 3rd Call
                  </button>
              </div>



              {/* Fourth Service Section */}
              <div className={`form-section ${isSectionReadOnly('fours') ? 'read-only' : ''}`}>
                  <h3>Fourth Site Visit Call</h3>

                  <label htmlFor="thirdServiceRemark">4th Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={fourVisitCallRemark}
                      onChange={(e) => setSFourVisitCallRemark(e.target.value)}
                      readOnly={!isEditable(fourVisitCallDate)}
                  ></textarea>
                  {fourVisitCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(fourVisitCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateFourthCall('fours')}
                      disabled={!isEditable(fourVisitCallDate)}
                  >
                      Add 4th Call
                  </button>
              </div>



                {/* Fifth Service Section */}
                <div className={`form-section ${isSectionReadOnly('fives') ? 'read-only' : ''}`}>
                  <h3>Fifth Site Visit Call</h3>

                  <label htmlFor="thirdServiceRemark">5th Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={fiveVisitCallRemark}
                      onChange={(e) => setFiveVisitCallRemark(e.target.value)}
                      readOnly={!isEditable(fiveVisitCallDate)}
                  ></textarea>
                  {fiveVisitCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(fiveVisitCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateFifthCall('fives')}
                      disabled={!isEditable(fiveVisitCallDate)}
                  >
                      Add 5th Call
                  </button>
              </div>



              {/*Visited persons*/}
              <div className={`form-section ${isSectionReadOnly('fives') ? 'read-only' : ''}`}>
                  <h3>Site Visited Persons</h3>

                  <label htmlFor="thirdServiceRemark">Person Names</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter Visited Person Names"
                      value={visitedPersons}
                      onChange={(e) => setVisitedPersons(e.target.value)}
                      readOnly={!isEditable(visitedDate)}
                     
                  ></textarea>

                  <label htmlFor="visitDate">Visit Date</label>
                  <input 
                      type="date" 
                      id="visitDate" 
                      name="visitDate" 
                      className="input-field" 
                      value={visitedDate}
                      onChange={(e) => setVisitedDate(e.target.value)}
                      readOnly={!isEditable(visitedDate)}
                  />    
                  {visitedDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(visitedDate).toLocaleString()}
                    </div>
                  )}              

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => AddVisitedPersons()}
                      disabled={!isEditable(visitedDate)}
                  >
                      Add site visit Details
                  </button>
              </div>



          </form>
      </div>

      {alert && (
          <div className={`alert-box ${alert.type}`}>
            {alert.message}
            <button onClick={() => setAlert(null)} className="alert-dismiss-button">OK</button>
          </div>
        )}

      <div>
      <Foot/>
    </div>
      </div>
  );
};

export default SiteVisitCalling;
