import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // For mobile responsiveness
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userPosition = sessionStorage.getItem('userPosition');

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavLinkClick = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Do you want to Logout?");
    if (confirmLogout) {
      sessionStorage.clear(); // Clear all session storage
      navigate('/login');
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#333', padding: '0 20px' }}>
      <Toolbar>

        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuItem onClick={() => handleNavLinkClick('/ClientAdmin')}>View Records</MenuItem>
              <MenuItem onClick={() => handleNavLinkClick('/profile')}>Profile Management</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <>
           <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {/* Add your title here */}
            </Typography>
            <Button color="inherit" onClick={() => handleNavLinkClick('/ClientAdmin')}>
              View Records
            </Button>
            <Button color="inherit" onClick={() => handleNavLinkClick('/profile')}>
              Profile Management
            </Button>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
