// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* frontend/src/Notification/NotificationDisplay.css */
.notification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.notification-card {
    width: 300px;
    margin: 10px;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

`, "",{"version":3,"sources":["webpack://./src/Employee/CSS/NotificationDisplay.css"],"names":[],"mappings":"AAAA,sDAAsD;AACtD;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,wCAAwC;IACxC,kBAAkB;AACtB","sourcesContent":["/* frontend/src/Notification/NotificationDisplay.css */\n.notification-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n}\n\n.notification-card {\n    width: 300px;\n    margin: 10px;\n    padding: 15px;\n    background-color: #f9f9f9;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
