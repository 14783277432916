import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navi from '../NavigationMain';
import Foot from '../footer';
import './CSS/ServiceHome.css'; 
import { CircularProgress } from "@mui/material";

const EmployeeProfile = () => {
  const [employeeData, setEmployeeData] = useState({
    eName: '',
    eEmail: '',
    totRecords: 0,
    totPoints: 0,
    avgPoints: 0,
  });
  const navigate = useNavigate();
  const [loadingcy, setLoadingcy] = useState(true);

  const userEmail = sessionStorage.getItem('userEmail');

  useEffect(() => {
    if (!userEmail) {
      navigate('/login');
    } else {
      getUsers();
    }
  }, [userEmail, navigate]);

  const EmpID = sessionStorage.getItem('EmpID');

  const getUsers = () => {
    const getAdminDetails = () => {
      Axios.get(process.env.REACT_APP_HOST_IP + `/api/selectedemployee?eId=${EmpID}`)
        .then((response) => {
            setEmployeeData(response.data?.response || []);
            setLoadingcy(false); 
        })
        .catch((error) => {
          console.error('Axios Error: ', error);
        });
    };
    getAdminDetails();
    const intervalId = setInterval(getAdminDetails, 1000);  //Update Getting Details every 1 second
    return () => clearInterval(intervalId);
};

  return (
    <div>
      {loadingcy ? (
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress size={150} />
         </div>
      ) : (
        <>

    <div class="container">
         <Navi/>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
      }}
    >
      <Paper elevation={3} sx={{ padding: '40px', width: '60%', borderRadius: '10px' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ color: '#1976d2' }}>
          My Profile
        </Typography>

        <Grid container spacing={3}>
          {/* Employee Name */}
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary">
              Employee Name:
            </Typography>
            <Typography variant="body1">{employeeData.eName || 'N/A'}</Typography>
          </Grid>

          {/* Employee Email */}
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary">
              Email Address:
            </Typography>
            <Typography variant="body1">{employeeData.eEmail || 'N/A'}</Typography>
          </Grid>

          {/* Total Records */}
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary">
              Total Records:
            </Typography>
            <Typography variant="body1">{employeeData.totRecords}</Typography>
          </Grid>

          {/* Total Points */}
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary">
              Total Points Earned:
            </Typography>
            <Typography variant="body1">{employeeData.totRates}</Typography>
          </Grid>

          {/* Average Points */}
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary">
              Average Points:
            </Typography>
            <Typography variant="body1">{(employeeData.totRates / employeeData.totRecords).toFixed(2)}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
    <Foot/>
    </div>
    </>
      )}
    </div>
  );
};

export default EmployeeProfile;
