import './CSS/adminHome.css';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import React, { useEffect, useState } from "react";
import Axios from 'axios';
import { CircularProgress } from "@mui/material";

const AdminHome = ({addUser, updateUser, submitted, data, isEdit, setIsEdit}) => {
  const [eId, setEid] = useState(0);
  const [eName, setEmpname] = useState('');
  const [eEmail, setEmpemail] = useState('');
  const [ePhone, setEmpmobile] = useState('');
  const [ePassword, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingcy, setLoadingcy] = useState(true);

  useEffect(() => {
    // Set initial data for Create new Product
    if (!submitted || isEdit) {
      setEid(0);
      setEmpname('');
      setEmpemail('');
      setEmpmobile('');
      setPassword('');
      fetchMaxIdAndSetId();
    }
    if (isEdit) { // Set Existing data for Updating existing Product
      setEid(data.eId);
      setEmpname(data.eName);
      setEmpemail(data.eEmail);
      setEmpmobile(data.ePhone);
      setPassword(data.ePassword);
    }
  }, [submitted, isEdit, data]);

  // Get maximum ID from API and set ID for new Product
  const fetchMaxIdAndSetId = async () => {
    try {
      const response = await Axios.get(process.env.REACT_APP_HOST_IP + '/api/getempmaxid');
      const maxId = response.data?.maxId || 0; 
      isEdit ? setEid(data.eId) : setEid(maxId + 1);  // set next product id
      setLoadingcy(false); 
    } catch (error) {
      console.error('Axios Error (getMaxId): ', error);
    }
  };


  // Function to handle updating Product data
  const handleUserDataUpdate = async () => {
    setLoading(true); // Set loading until upload all data

    try {
      // If in Update product, update the existing Product data, otherwise add a new Product
      isEdit ? updateUser({ eId, eName, eEmail, ePhone, ePassword}) : addUser({ eId, eName, eEmail, ePhone, ePassword });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Cloase loading after saving data
    }
  };


  //Main Upload button
  const handleSaveButtonClick = async (e) => {
    e.preventDefault();
    await handleUserDataUpdate();
    setIsEdit(false); // Cloase loading after saving data
  };

  return (
    <div>

      {loadingcy ? (
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress size={150} />
         </div>
      ) : (
        <>

    <div id="formStart5">
        <h2 className='topicTitle'>{isEdit ? "Update Employee Details" : "Add New Employee"}</h2>
        <form>
          <div className="input-group">
            <label htmlFor="employeeId" className='itemRow'>Employee ID</label>
            <input 
              type="number" 
              id="eid" 
              name="eid"
              value={eId} 
              onChange={(e) => setEid(e.target.value)}
              readOnly  
              className='GetItemDetails5'
            />
          </div>

          <div className="input-group">
            <label htmlFor="employeeName" className='itemRow'>Employee Name</label>
            <input 
              type="text" 
              id="empname" 
              name="empname"
              value={eName} 
              onChange={(e) => setEmpname(e.target.value)}
              placeholder="Enter employee name"
              required
              className='GetItemDetails5'
            />
          </div>

          <div className="input-group">
            <label htmlFor="employeeEmail" className='itemRow'>Employee Email Address</label>
            <input 
              type="email" 
              id="empemail" 
              value={eEmail} 
              onChange={(e) => setEmpemail(e.target.value)} 
              placeholder="Enter employee email"
              required
              className='GetItemDetails5'
            />
          </div>

          <div className="input-group">
            <label htmlFor="employeeMobile" className='itemRow'>Employee Mobile Phone</label>
            <input 
              type="text" 
              id="empmobile" 
              value={ePhone} 
              onChange={(e) => setEmpmobile(e.target.value)} 
              placeholder="Enter employee mobile"
              required
              className='GetItemDetails5'
            />
          </div>

          <div className="input-group password-group">
            <label htmlFor="password" className='itemRow'>Password </label>
            <div className="password-wrapper">
              <input 
                type={showPassword ? 'text' : 'password'} 
                id="password" 
                value={ePassword} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder="Enter password"
                required
                className='GetItemDetails5'
              />
              <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
          </div>

          <div className="input-group password-group">
            <label htmlFor="repeatPassword" className='itemRow'>Repeat Password</label>
            <div className="password-wrapper">
              <input 
                type={showRepeatPassword ? 'text' : 'password'} 
                id="repeatPassword" 
                value={repeatPassword} 
                onChange={(e) => setRepeatPassword(e.target.value)} 
                placeholder="Repeat password"
                required
                className='GetItemDetails5'
              />
              <span className="password-toggle" onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
                {showRepeatPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
          </div>

          <div className="center-button">
              <button
                  className="button"
                  onClick={handleSaveButtonClick}
                >
                  {isEdit ? "Update Profile" : "Add Profile"}
                </button>
            </div>
        </form>
      </div>
      </>
      )}
      </div>
  );
};

export default AdminHome;
