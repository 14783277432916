import React, { useState, useEffect } from 'react';
import './CSS/ProfileDisplay.css';
import { Paper, Button, TableContainer, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { jsPDF } from 'jspdf';
import logo from '../image/logo.jpg'
import axios from 'axios';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom'; // Ensure useNavigate is imported

const ProfileDisplay = ({ rows, selectedUser, deleteUser, loadingcy }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSiteVisitedCategory, setSiteVisitCategory] = useState([]);
  const [selectedClearanceCategory, setClearanceCategory] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedSeeMore, setSelectedSeeMore] = useState(null);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [openPDFDialog, setOpenPDFDialog] = useState(false);
  const [openSeeMoreDialog, setOpenSeeMoreDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingSeeMore, setLoadingSeeMore] = useState({}); 
  const [newCid, setNewCid] = useState({}); 
  const [newRow, setNewRow] = useState({}); 
  
  const [colorFilter, setColorFilter] = useState(''); 

  const [isLoading, setIsLoading] = useState(false);  // To track the loading state


  const [pdfLink, setPdfLink] = useState('');
  const [updatedRows, setUpdatedRows] = useState(rows);
  const [sendFilter, setSendFilter] = useState('All'); // New state for send/not send filter

  const navigate = useNavigate(); // Initialize useNavigate
  const [open, setOpen] = useState(false); // State for dialog open/close

  const [openColorDialog, setOpenColorDialog] = useState(false); // State for color dialog
  const [selectedRow, setSelectedRow] = useState(null); // State to track the selected row for updating priority

  const [openAddClientDialog, setOpenAddClientDialog] = useState(false); // State for the add client dialog
  const [newKW, setNewKW] = useState(''); // State for Number Of KW
  const [newPrice, setNewPrice] = useState(''); // State for Price
  const [newPanels, setNewPanels] = useState(''); // State for No of Panels
  const [newUnits, setNewUnits] = useState(''); // State for No of Panels

  // Set initial state for selectedCategory
  useEffect(() => {
    setSelectedCategory([]); // Set to empty array to check "All" radio button
  }, []); // Empty dependency array to run only on mount

  const handleClickOpen = (cid) => {
    setOpen(cid); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleClearanceCall = (cid) => {
    navigate(`/ClearanceCalling/${cid}`); // Navigate to ClearanceCalling
    handleClose(); // Close the dialog
  };

  const handleSiteVisitCall = (cid) => {
    navigate(`/SiteVisitCalling/${cid}`); // Navigate to SiteVisitCalling
    handleClose(); // Close the dialog
  };

  const handleAdvanceCall = (cid) => {
    navigate(`/AdvanceCalling/${cid}`); // Navigate to AdvanceCalling
    handleClose(); // Close the dialog
  };

  // Handle update button click Function
  const handleUpdateButtonClick = (cid) => {
    const selectedUserData = rows.find(row => row.cid === cid);
    selectedUser(selectedUserData);
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setOpenNoteDialog(true);
  };

  const handleSeeMoreClick = async (cid, cName, gender, jobJole, formattedDate, cPhone, city, nKW, Units, ElecType, BusinessType, SystemType, CurrentType, InstallationType, note, nPanel, Price, empName, ClearanceStatus, ClearanceDate) => {

    setLoadingSeeMore(prev => ({ ...prev, [cid]: true })); // Set loading for the specific record
    let res1 = {}; // Initialize res1 as an empty object
    let res2 = {}; // Initialize res2 as an empty object
    let res3 = {};

    try {
        const clientResponse = await axios.get(process.env.REACT_APP_HOST_IP + `/api/clearance?cid=${cid}`);
        res1 = clientResponse.data.response; // Assign response if successful
    } catch (error) {
        console.error('Error fetching clearance data:', error);
        res1 = {}; // Set to empty result if API call fails
    }

    try {
        const clientResponse2 = await axios.get(process.env.REACT_APP_HOST_IP + `/api/sitevisit?cid=${cid}`);
        res2 = clientResponse2.data.response; // Assign response if successful
    } catch (error) {
        console.error('Error fetching site visit data:', error);
        res2 = {}; // Set to empty result if API call fails
    }

    try {
      const clientResponse3 = await axios.get(process.env.REACT_APP_HOST_IP + `/api/advances?cid=${cid}`);
      res3 = clientResponse3.data.response; // Assign response if successful
    } catch (error) {
        console.error('Error fetching Advance data:', error);
        res3 = {}; // Set to empty result if API call fails
    }

    setSelectedSeeMore({ cid, cName, gender, jobJole, formattedDate, cPhone, city, nKW, Units, ElecType, BusinessType, SystemType, CurrentType, InstallationType, note, nPanel, Price, empName, ClearanceStatus, ClearanceDate, res1, res2, res3  });
    setOpenSeeMoreDialog(true);
    setLoadingSeeMore(prev => ({ ...prev, [cid]: false }));
  };

  function formatDate2(dateString) {
    const [day, month, year] = dateString.split('/');
    const shortYear = year.slice(-2);
    const formattedMonth = month.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
  
    return `${formattedDay}${formattedMonth}${shortYear}`;
  }

  function getInitials(fullName) {
    const nameArray = fullName.split(' ');
    let initials = nameArray[0][0];

    if (nameArray.length > 1) {
      initials += nameArray[1][0];
    }
  
    return initials.toUpperCase();
  }

  const handlePDFClick = async (cid, InstallationType, cName, gender, city, nKW, CurrentType, date, Units, nPanel, Price, empName) => {

    setIsLoading(true);

    const refDate = formatDate2(formatDate(Date()));
    const refName = getInitials(empName);

    const refNo = refName+'-'+refDate+'-'+cid;

    if(InstallationType=='Semi-German'){
      const payload = {
        refNo: refNo || 0,
        nKW: nKW || 0,
        CurrentType: CurrentType ? CurrentType.toUpperCase() : null,
        cName: cName ? cName.toUpperCase() : null,
        gender: gender || 'Mr',
        city: city || null,
        date: formatDate(Date()) || null,
        Units: Units || 0,
        nPanel: nPanel || 0,
        Price: Price ? Price.toLocaleString('en-US') : 0
      };
  
      try {
        // Ensure responseType is 'blob' to correctly handle binary data (PDF)
        const response = await axios.post(process.env.REACT_APP_HOST_IP + '/api/generate-pdf', payload, {
          responseType: 'blob' // Important: Handle binary data
        });
  
        // Create a link to the PDF
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setPdfLink(fileURL);
        setIsLoading(false);
  
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
    else if (InstallationType=='Full-German'){
      const Fullpayload = {
        refNo: refNo || 0,
        nKW: nKW || 0,
        CurrentType: CurrentType ? CurrentType.toUpperCase() : null,
        cName: cName ? cName.toUpperCase() : null,
        gender: gender || 'Mr',
        city: city || null,
        date: formatDate(Date()) || null,
        Units: Units || 0,
        nPanel: nPanel || 0,
        Price: Price ? Price.toLocaleString('en-US') : 0
      };
  
      try {
        // Ensure responseType is 'blob' to correctly handle binary data (PDF)
        const response = await axios.post(process.env.REACT_APP_HOST_IP + '/api/generate-pdf-full', Fullpayload, {
          responseType: 'blob' // Important: Handle binary data
        });
  
        // Create a link to the PDF
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setPdfLink(fileURL);
        setIsLoading(false);
  
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }

    handleDownloadPDF(cid);
    setOpenPDFDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  const handleCloseSeeMoreDialog = () => {
    setOpenSeeMoreDialog(false);
  };

  const handleClosePDFDialog = () => {
    setOpenPDFDialog(false);
  };

  const handleSendPDFToggle = async (cid, currentStatus) => {
    const newStatus = currentStatus === 'yes' ? 'no' : 'yes';
    try {
      // Make an API call to update the sendPDF status
      const payload = {
        cid: cid,
        sendPDF: newStatus
      }
      console.log(payload)
      await axios.post(`${process.env.REACT_APP_HOST_IP}/api/updateclient`,payload);

      // Update the local state
      setUpdatedRows(prevRows =>
        prevRows.map(row =>
          row.cid === cid ? { ...row, sendPDF: newStatus } : row
        )
      );
    } catch (error) {
      console.error('Error updating sendPDF status:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleSiteVisitToggle = async (cid, currentStatus) => {
    const newStatus = currentStatus === 'Yes' ? 'No' : 'Yes';
    try {
      // Make an API call to update the sendPDF status
      const payload = {
        cid: cid,
        siteVisitStatus: newStatus
      }
      console.log(payload)
      await axios.post(`${process.env.REACT_APP_HOST_IP}/api/updateclient`,payload);

      // Update the local state
      setUpdatedRows(prevRows =>
        prevRows.map(row =>
          row.cid === cid ? { ...row, siteVisitStatus: newStatus } : row
        )
      );
    } catch (error) {
      console.error('Error updating sendPDF status:', error);
      // Optionally, show an error message to the user
    }
  };

  // Use updatedRows instead of rows for renderin

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredRows = Array.isArray(rows) && (selectedCategory.length === 0 ? rows : rows.filter(row => selectedCategory.includes(row.ElecType)));
  const filteredSiteRows = Array.isArray(filteredRows) && (selectedSiteVisitedCategory.length === 0 ? filteredRows : filteredRows.filter(row => selectedSiteVisitedCategory.includes(row.siteVisitStatus)));
  const filteredClearanceRows = Array.isArray(filteredSiteRows) && (selectedClearanceCategory.length === 0 ? filteredSiteRows : filteredSiteRows.filter(row => selectedClearanceCategory.includes(row.ClearanceStatus)));

  const visibleRows = Array.isArray(filteredClearanceRows) && filteredClearanceRows.filter(row => {
    const searchTermLowerCase = searchTerm.toLowerCase();
  
    const matchesSearchTerm = row.cName.toLowerCase().includes(searchTermLowerCase) 
      || row.cid.toString().includes(searchTermLowerCase) 
      || row.city.toLowerCase().includes(searchTermLowerCase)
      || row.cPhone.toLowerCase().includes(searchTermLowerCase);
  
  
    // Ensure proper date conversion
    const rowDate = new Date(row.regDate);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
  
    const matchesDateRange = (!start || rowDate >= start) && (!end || rowDate <= end);
  
    // Check for color filter match
    const matchesColorFilter = !colorFilter || row.ColorStatus === colorFilter;
  
    return matchesSearchTerm && matchesDateRange && matchesColorFilter;
  }).sort((a, b) => b.cid - a.cid);

  const clearDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    
    // Add company logo
    doc.addImage(logo, 'PNG', 20, 10, 30, 30); // Ensure 'logo' is imported correctly
    
    // Add company name and details
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(16);
    doc.text('Archer Prime International (Pvt)Ltd', 60, 20); // Company name next to logo
    
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.text('Solar Power Company', 60, 28);
    doc.text('Contact: info@archerprime.lk | www.archerprime.lk', 60, 35);
    
    // Add a horizontal line below the header
    doc.setLineWidth(0.5);
    doc.line(20, 45, 190, 45);
    
    // Title Section
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(18);
    doc.setTextColor(40, 40, 40);
    doc.text('Client Details Report', 105, 55, { align: 'center' });
    
    // Add another line below the title
    doc.setLineWidth(0.5);
    doc.line(20, 60, 190, 60);
    
    // Set font size and color for details
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(14);
    doc.setTextColor(60, 60, 60);
    
    // Define content for client details
    const content = [
      { label: 'Client ID:', value: selectedSeeMore.cid || 'Not Available' },
      { label: 'Client Name:', value: selectedSeeMore.cName || 'Not Available' },
      { label: 'Gender:', value: selectedSeeMore.gender || 'Not Available' },
      { label: 'Date:', value: selectedSeeMore.formattedDate || 'Not Available' },
      { label: 'Phone Number:', value: selectedSeeMore.cPhone || 'Not Available' },
      { label: 'City:', value: selectedSeeMore.city || 'Not Available' },
      { label: 'Number of KW:', value: selectedSeeMore.nKW || 'Not Available' },
      { label: 'Number of Units:', value: selectedSeeMore.Units || 'Not Available' },
      { label: 'Electricity Type:', value: selectedSeeMore.ElecType || 'Not Available' },
      { label: 'Business Type:', value: selectedSeeMore.BusinessType || 'Not Available' },
      { label: 'System Type:', value: selectedSeeMore.SystemType || 'Not Available' },
      { label: 'Current Type:', value: selectedSeeMore.CurrentType || 'Not Available' },
      { label: 'Installation Type:', value: selectedSeeMore.InstallationType || 'Not Available' },
      { label: 'No of Panels:', value: selectedSeeMore.nPanel || 'Not Available'},
      { label: 'Special Note:', value: selectedSeeMore.note || 'Not Available'},
      { label: 'Price:', value: selectedSeeMore.Price || 'Not Available' },
      { label: 'Clearance Status:', value: selectedSeeMore.ClearanceStatus || 'Not Available' },
    ];
  
    // Define start position for details
    let yPosition = 70; // Adjusted to accommodate the content above
    
    // Loop through and add each client detail to the PDF
    content.forEach((item) => {
      doc.setFont('Helvetica', 'bold');
      doc.text(item.label, 20, yPosition);
      
      doc.setFont('Helvetica', 'normal');
      doc.text(item.value.toString(), 80, yPosition);
      
      yPosition += 10;
    });
    
    // Add a footer
    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100);
    doc.text('Generated by Archer Prime International (Pvt)Ltd - IT Section', 105, 290, { align: 'center' });

    // Add generated date and person at the bottom
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(); // Format the date as needed (e.g., 'MM/DD/YYYY')
    const generatedPerson = selectedSeeMore.empName;
    
    // Position the date and person closer to the bottom
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.text(`Generated on: ${formattedDate}`, 20, 260);
    doc.text(`Generated by: ${generatedPerson}`, 20, 268);

    // Save the PDF
    doc.save('client-details.pdf');
};

  
  
  const handleDownloadPDF = async (cid) => {
    const payload = {
      cid: cid,
      pdfStatus: 'yes'
    }
    console.log(payload)
    await axios.post(`${process.env.REACT_APP_HOST_IP}/api/updateclient`,payload);
  };

  const handleColorClick = (row) => {
    setSelectedRow(row); // Set the selected row
    setOpenColorDialog(true); // Open the color selection dialog
  };

  const handleColorSelect = async (color) => {
    try {
      await axios.post(`${process.env.REACT_APP_HOST_IP}/api/updateclient`, {
        cid: selectedRow.cid,
        ColorStatus: color // Send the selected color to the API
      });
      // Optionally, update the local state to reflect the change
      setUpdatedRows(prevRows =>
        prevRows.map(row =>
          row.cid === selectedRow.cid ? { ...row, ColorStatus: color } : row
        )
      );
    } catch (error) {
      console.error('Error updating priority color:', error);
    } finally {
      setOpenColorDialog(false); // Close the dialog after selection
    }
  };

  const handleColorFilter = (color) => {
    setColorFilter(color);
  };

  // New function to handle copying client data
  const handleCopyButtonClick = (row) => {
    openAddClientPopup(row);
    
  };

  const fetchMaxIdAndSetId = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_HOST_IP + '/api/getmaxid');
      const maxId = response.data?.maxId || 0; 
      setNewCid(maxId+1) // set next product id
    } catch (error) {
      console.error('Axios Error (getMaxId): ', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchMaxIdAndSetId, 1000); // Refresh every 5 seconds

    // Initial fetch
    fetchMaxIdAndSetId();

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  // Function to handle adding a new client
  const handleAddClient = async (row) => {
    const payload = {
        ...row, // Spread existing row data
        cid: newCid, // Use the new client ID
        nKW: newKW, // Use new Number Of KW
        Units: newUnits,
        Price: newPrice, // Use new Price
        nPanel: newPanels // Use new No of Panels
    };

    try {
      // Call the API to create a new client
      await axios.post(`${process.env.REACT_APP_HOST_IP}/api/createclient`, payload);
      // Optionally, close the dialog and reset fields
      setOpenAddClientDialog(false);
      setNewKW('');
      setNewPrice('');
      setNewPanels('');
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  // Function to open the add client dialog
  const openAddClientPopup = (row) => {
    setNewRow(row);
    setOpenAddClientDialog(true);
  };

  return (
    <div className="admin-display-container">
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress size={150} />
        </div>
      ) : (
        <>
          <div className='top-actions'>
            <div className="date-filter-group">
            <div className="date-filter-ind">
                <label htmlFor="start-date">Start Date</label>
                <input
                  type="date"
                  id="start-date"
                  className="styled-date-input1"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="date-filter-ind">
                <label htmlFor="end-date">End Date</label>
                <input
                  type="date"
                  id="end-date"
                  className="styled-date-input1"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <button onClick={clearDateFilters} variant="outlined" color="secondary" class="clear-btn">Clear Dates</button>
            </div>

            <input
              type="text"
              className="admin-search-input"
              placeholder="Search by Client ID or Name or City..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>


          <div className="admin-button-group-admin">

            <Tooltip title="Site visit done, CEB approved and accept our deal" arrow placement="bottom">
                <button onClick={() => handleColorFilter('green')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'green' }}></button>
            </Tooltip>
            <Tooltip title="Site visit done, CEB not approved and accept our deal" arrow placement="bottom">
                <button onClick={() => handleColorFilter('yellow')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'yellow' }}></button>
            </Tooltip>
            <Tooltip title="Maximum 3 week Delay" arrow placement="bottom">
                <button onClick={() => handleColorFilter('pink')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'pink' }}></button>
            </Tooltip>
            <Tooltip title="Maximum 4 month Delay" arrow placement="bottom">
                <button onClick={() => handleColorFilter('blue')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'blue' }}></button>
            </Tooltip>
            <Tooltip title="Rejected" arrow placement="bottom">
                <button onClick={() => handleColorFilter('red')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '30px', backgroundColor: 'red' }}></button>
            </Tooltip>
            <Tooltip title="Price Issue and Outside our system" arrow placement="bottom">
                <button onClick={() => handleColorFilter('purple')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'purple' }}></button>
            </Tooltip>
            <button onClick={() => handleColorFilter('')}  style={{ marginRight: '80px'}} >All Colors</button>

           
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="radio-group">
                    <label>
                        <input 
                            type="radio" 
                            value="All" 
                            checked={selectedCategory.length === 0} 
                            onChange={() => {
                                setSelectedCategory([]); // Clear selection when "All" is clicked
                            }} 
                        />
                        <span>All Types</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="CEB" 
                            checked={selectedCategory.includes('CEB')} 
                            onChange={() => {
                                setSelectedCategory(['CEB']); // Set selected category to CEB
                            }} 
                        />
                        <span>CEB</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="LECO" 
                            checked={selectedCategory.includes('LECO')} 
                            onChange={() => {
                                setSelectedCategory(['LECO']); // Set selected category to LECO
                            }} 
                        />
                        <span>LECO</span>
                    </label>
                </div>

                <div className="radio-group">
                    <label>
                        <input 
                            type="radio" 
                            value="All" 
                            checked={selectedSiteVisitedCategory.length === 0} 
                            onChange={() => {
                                setSiteVisitCategory([]); // Clear selection when "All" is clicked
                            }} 
                        />
                        <span>All Types</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="Yes" 
                            checked={selectedSiteVisitedCategory.includes('Yes')} 
                            onChange={() => {
                                setSiteVisitCategory(['Yes']); // Set selected category to Yes
                            }} 
                        />
                        <span>Site visited</span>
                    </label>
                </div>

                <div className="radio-group">
                    <label>
                        <input 
                            type="radio" 
                            value="All" 
                            checked={selectedClearanceCategory.length === 0} 
                            onChange={() => {
                                setClearanceCategory([]); // Clear selection when "All" is clicked
                            }} 
                        />
                        <span>All Types</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="Yes" 
                            checked={selectedClearanceCategory.includes('Yes')} 
                            onChange={() => {
                                setClearanceCategory(['Yes']); // Set selected category to Yes
                            }} 
                        />
                        <span>Clearance GET</span>
                    </label>
                </div>
            </div>
          </div>

          <TableContainer component={Paper}>
            <table className="admin-table">
              <thead>
                <tr>
                  <th>Client ID</th>
                  <th>Priority</th>
                  <th>Client Name</th>
                  <th>Date</th>
                  <th>Phone Number</th>
                  <th>No.Of KW</th>
                  <th>City</th>
                  <th>More Details</th>
                  <th>Calls</th>
                  <th>Rate</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {visibleRows.map((row) => {
                  const formattedDate = formatDate(row.regDate);
                  return (
                    <tr key={row.cid}>
                      <td>{row.cid}</td>
                      <td onClick={() => handleColorClick(row)} style={{ cursor: 'pointer' }}>
                        <span style={{ display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', border: '2px solid black', backgroundColor: row.ColorStatus || 'white' }}></span>
                      </td>
                      <td>{row.cName}</td>
                      <td>{formattedDate}</td>
                      <td>{row.cPhone}</td>
                      <td>{row.nKW}</td>
                      <td>{row.city}</td>
                      <td><button onClick={() => handleNoteClick(row.note)} className="admin-location-button">Note</button>
                          <button onClick={() => handleSeeMoreClick(row.cid, row.cName, row.gender, row.jobJole, formattedDate, row.cPhone, row.city, row.nKW, row.Units, row.ElecType, row.BusinessType, row.SystemType, row.CurrentType, row.InstallationType, row.note, row.nPanel, row.Price, row.empName, row.ClearanceStatus,  row.ClearanceDate)} className="admin-location-button">More</button>
                          {loadingSeeMore[row.cid] && <CircularProgress size={24} style={{ marginLeft: '10px' }} />}
                          
                          <Switch
                          checked={row.siteVisitStatus === 'Yes'}
                          onChange={() => handleSiteVisitToggle(row.cid, row.siteVisitStatus)}
                          color="primary"
                        />
                        {/* <button onClick={() => handleCopyButtonClick(row)} className="admin-location-button">Copy</button> */}
                          
                          </td>
                      <td><button onClick={() => handleClickOpen(row.cid)} className="admin-location-button">
                            Calls
                          </button>
                      </td>
                      <td>{row.cRate}</td>
                      <td>
                        <button
                          onClick={() => handleUpdateButtonClick(row.cid)}
                          className="admin-update-button">Update
                        </button>
                        {/* <button
                          onClick={() => deleteUser({ cid: row.cid })}
                          className="admin-delete-button"
                          disabled>Delete
                        </button> */}
                        <button onClick={() => handlePDFClick(row.cid, row.InstallationType, row.cName, row.gender, row.city, row.nKW, row.CurrentType, formattedDate, row.Units ,row.nPanel, row.Price, row.empName)} className="admin-location-button">Proposal</button>
                        <Switch
                          checked={row.sendPDF === 'yes'}
                          onChange={() => handleSendPDFToggle(row.cid, row.sendPDF)}
                          color="primary"
                        />
                        <span>{row.sendPDF === 'yes' ? 'Send' : 'Not Send'}</span>
                        {row.pdfStatus === 'yes' ? (
                          <span style={{ color: 'gold', marginLeft: '5px' }}>★</span>
                        ) : (
                          <span style={{ color: '#888', marginLeft: '5px' }}>★</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>

          <Dialog open={openNoteDialog} onClose={handleCloseNoteDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
              Special Note
              <IconButton edge="end" color="inherit" onClick={handleCloseNoteDialog} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {selectedNote ? (
                <div>
                  <h4>Special Note:</h4>
                  <p>{selectedNote}</p>
                </div>
              ) : (
                <p>No Note available.</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNoteDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>



         {/* See More Dialog */}
         <Dialog open={openSeeMoreDialog} onClose={handleCloseSeeMoreDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
              More Details
              <IconButton edge="end" color="inherit" onClick={handleCloseSeeMoreDialog} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {selectedSeeMore ? (
                <div className='seemore-popup'>
                <h4>Client ID: <span>{selectedSeeMore.cid}</span></h4>
                <h4>Client Name: <span>{selectedSeeMore.gender}. {selectedSeeMore.cName}</span></h4>
                <h4>Date: <span>{selectedSeeMore.formattedDate}</span></h4>
                <h4>Client Phone Number: <span>{selectedSeeMore.cPhone}</span></h4>
                <h4>Client City: <span>{selectedSeeMore.city}</span></h4>
                <h4>Job Role: <span>{selectedSeeMore.jobJole}</span></h4>
                <h4>Number Of KW: <span>{selectedSeeMore.nKW}</span></h4>
                <h4>Number Of Units: <span>{selectedSeeMore.Units}</span></h4>
                <h4>Electricity Type: <span>{selectedSeeMore.ElecType}</span></h4>
                <h4>Business Type: <span>{selectedSeeMore.BusinessType}</span></h4>
                <h4>System Type: <span>{selectedSeeMore.SystemType}</span></h4>
                <h4>Current Type: <span>{selectedSeeMore.CurrentType}</span></h4>
                <h4>Installation Type: <span>{selectedSeeMore.InstallationType}</span></h4>
                <h4>Special Note: <span>{selectedSeeMore.note}</span></h4>
                <hr></hr>

                <h3>Clearance</h3>

                <h4>Clearance Status: <span>{selectedSeeMore.ClearanceStatus === 'Yes' ? 'Get Clearance' : 'Not given Yet'}</span></h4><br></br>

                <h4>1st Clearance call: <span>{selectedSeeMore.res1.fCallRemark || 'Not yet'}</span></h4>
                <h4>1st Clearance call Date: <span>{new Date(selectedSeeMore.res1.fCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>2nd Clearance call: <span>{selectedSeeMore.res1.sCallRemark || 'Not yet'}</span></h4>
                <h4>2nd Clearance call Date: <span>{new Date(selectedSeeMore.res1.sCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>3rd Clearance call: <span>{selectedSeeMore.res1.tCallRemark || 'Not yet'}</span></h4>
                <h4>3rd Clearance call Date: <span>{new Date(selectedSeeMore.res1.tCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>4th Clearance call: <span>{selectedSeeMore.res1.fourCallRemark || 'Not yet'}</span></h4>
                <h4>4th Clearance call Date: <span>{new Date(selectedSeeMore.res1.fourCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>5th Clearance call: <span>{selectedSeeMore.res1.fiveCallRemark || 'Not yet'}</span></h4>
                <h4>5th Clearance call Date: <span>{new Date(selectedSeeMore.res1.fiveCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>6th Clearance call: <span>{selectedSeeMore.res1.sixCallRemark || 'Not yet'}</span></h4>
                <h4>6th Clearance call Date: <span>{new Date(selectedSeeMore.res1.sixCallDate).toLocaleDateString() || 'Not yet'}</span></h4>

                <hr></hr>

                <h3>Site Visit</h3>

                <h4>Site Visited Persons: <span>{selectedSeeMore.res2.visitedPersons || 'Not yet'}</span></h4>
                <h4>Site Visited Date: <span>{new Date(selectedSeeMore.res2.visitedDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>Site visit status: <span>{selectedSeeMore.res2.visitGet === 'Yes' ? 'Got a date' : 'No date Yet'}</span></h4><br></br>
                <h4>1st Site Visit call: <span>{selectedSeeMore.res2.fVisitCallRemark || 'Not yet'}</span></h4>
                <h4>1st Site Visit call Date: <span>{new Date(selectedSeeMore.res2.fVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>2nd Site Visit call: <span>{selectedSeeMore.res2.sVisitCallRemark || 'Not yet'}</span></h4>
                <h4>2nd Site Visit call Date: <span>{new Date(selectedSeeMore.res2.sVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>3rd Site Visit call: <span>{selectedSeeMore.res2.tVisitCallRemark || 'Not yet'}</span></h4>
                <h4>3rd Site Visit call Date: <span>{new Date(selectedSeeMore.res2.tVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>4th Site Visit call: <span>{selectedSeeMore.res2.fourVisitCallRemark || 'Not yet'}</span></h4>
                <h4>4th Site Visit call Date: <span>{new Date(selectedSeeMore.res2.fourVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>5th Site Visit call: <span>{selectedSeeMore.res2.fiveVisitCallRemark || 'Not yet'}</span></h4>
                <h4>5th Site Visit call Date: <span>{new Date(selectedSeeMore.res2.fiveVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <hr></hr>

                <h3>Advance</h3>

                <h4>Advance Status: <span>{selectedSeeMore.res3.advanceGet === 'Yes' ? 'Take a Advance' : 'Advance not taken'}</span></h4><br></br>
                <h4>1st Advance call: <span>{selectedSeeMore.res3.fAdvanceCallRemark || 'Not yet'}</span></h4>
                <h4>1st Advance call Date: <span>{new Date(selectedSeeMore.res3.fAdvanceCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>2nd Advance call: <span>{selectedSeeMore.res3.sAdvanceCallRemark || 'Not yet'}</span></h4>
                <h4>2nd Advance call Date: <span>{new Date(selectedSeeMore.res3.sAdvanceCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>3rd Advance call: <span>{selectedSeeMore.res3.tAdvanceCallRemark || 'Not yet'}</span></h4>
                <h4>3rd Advance call Date: <span>{new Date(selectedSeeMore.res3.tAdvanceCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <Button onClick={generatePDF} variant="contained" color="primary" className="download-pdf-button">Download PDF</Button>
              </div>              
              ) : (
                <p>No details available.</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSeeMoreDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog open={openPDFDialog} onClose={handleClosePDFDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
              Proposal Generate
              <IconButton edge="end" color="inherit" onClick={handleClosePDFDialog} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
            {pdfLink && (
                <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: '300px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
                <h4 style={{ color: 'black', fontSize: '18px', marginBottom: '15px' }}>PDF generated! Click below to download:</h4>
                <a href={pdfLink} download="ArcherPrime_Project_Proposal.pdf">
                  <button style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', fontSize: '16px' }}>
                    Download PDF
                  </button>
                </a>
              </div>              
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePDFDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              Select an Call Type
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {/* Additional content can be added here if needed */}
            </DialogContent>
            <DialogActions>
              <button onClick={() => handleClearanceCall(open)} style={{ backgroundColor: 'blue', color: 'white' }} class='admin-update-button'>
                Clearance Calls
              </button>
              <button onClick={() => handleSiteVisitCall(open)} style={{ backgroundColor: 'blue', color: 'white' }} class='admin-update-button'>
                Site Visit Calls
              </button>
              <button onClick={() => handleAdvanceCall(open)} style={{ backgroundColor: 'blue', color: 'white' }} class='admin-update-button'>
                Advance Calls
              </button>
            </DialogActions>
          </Dialog>
{/* site visit, ceb clearance, accept deal */}
          <Dialog open={openColorDialog} onClose={() => setOpenColorDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Select Priority Color</DialogTitle>
            <DialogContent>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {['green', 'yellow', 'pink', 'blue', 'red', 'purple'].map((color, index) => {
                  const priorityLevels = ['Site visit done and CEB approved', 'Site done visit and CEB not approved', 'Maximum 3 week Delay', 'Maximum 4 month Delay', 'Rejected', 'Price issue and Outside our system'];
                  return (
                    <div style={{ textAlign: 'center', marginRight: '20px' }} key={color}>
                      <span
                        onClick={() => handleColorSelect(color)}
                        style={{
                          display: 'inline-block',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          backgroundColor: color,
                          cursor: 'pointer',
                          border: '2px solid black'
                        }}
                      />
                      <div>{priorityLevels[index]}</div> {/* Display priority level */}
                    </div>
                  );
                })}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenColorDialog(false)} color="primary">Close</Button>
            </DialogActions>
          </Dialog>

          {/* Add Client Dialog */}
          <Dialog open={openAddClientDialog} onClose={() => setOpenAddClientDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle>
              Add New Client
              <IconButton edge="end" color="inherit" onClick={() => setOpenAddClientDialog(false)} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Number Of KW"
                value={newKW}
                onChange={(e) => setNewKW(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Number Of Units"
                value={newUnits}
                onChange={(e) => setNewUnits(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Price"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="No of Panels"
                value={newPanels}
                onChange={(e) => setNewPanels(e.target.value)}
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAddClientDialog(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleAddClient(newRow)} color="primary">
                Add Client
              </Button>
            </DialogActions>
          </Dialog>

        </>
      )}
    </div>
  );
};

export default ProfileDisplay;
