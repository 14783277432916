import './CSS/adminHome.css';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import React, { useEffect, useState } from "react";
import Axios from 'axios';
import { CircularProgress, LinearProgress } from "@mui/material";

const AdminHome = ({addUser, updateUser, submitted, data, isEdit, setIsEdit}) => {
  const [cid, setCid] = useState(0);
  const [cName, setcName] = useState('');
  const [gender, setGender] = useState('');
  const [jobJole, setJobJole] = useState('');
  const [cPhone, setcPhone] = useState('');
  const [nKW, setnKW] = useState();
  const [Units, setUnits] = useState();
  const [ElecType, setElecType] = useState('');
  const [city, setCity] = useState('');
  const [BusinessType, setBusinessType] = useState('');
  const [SystemType, setSystemType] = useState('');
  const [CurrentType, setCurrentType] = useState('');
  const [InstallationType, setInstallationType] = useState('');
  const [note, setNote] = useState('');
  const [nPanel, setNPanel] = useState(0);
  const [Price, setPrice] = useState(0);
  const [ClearanceStatus, setClearanceStatus] = useState('');
 

  const [loading, setLoading] = useState(false);
  const [loadingcy, setLoadingcy] = useState(true);

  useEffect(() => {
    // Set initial data for Create new Product
    if (!submitted || isEdit) {
      setCid(0);
      setcName('');
      setGender('');
      setJobJole('');
      setcPhone('');
      setnKW();
      setUnits();
      setElecType('');
      setCity('');
      setBusinessType('');
      setSystemType('');
      setCurrentType('');
      setInstallationType('');
      setNote('');
      setNPanel(0);
      setPrice(0);
      setClearanceStatus('');

      fetchMaxIdAndSetId();
    }
    if (isEdit) { // Set Existing data for Updating existing Product
      setCid(data.cid);
      setcName(data.cName);
      setGender(data.gender)
      setJobJole(data.jobJole)
      setcPhone(data.cPhone);
      setnKW(data.nKW);
      setUnits(data.Units)
      setElecType(data.ElecType);
      setCity(data.city);
      setBusinessType(data.BusinessType);
      setSystemType(data.SystemType);
      setCurrentType(data.CurrentType);
      setInstallationType(data.InstallationType);
      setNote(data.note);
      setNPanel(data.nPanel);
      setPrice(data.Price);
      setClearanceStatus(data.ClearanceStatus);
    }
  }, [submitted, isEdit, data]);

  // Get maximum ID from API and set ID for new Product
  const fetchMaxIdAndSetId = async () => {
    try {
      const response = await Axios.get(process.env.REACT_APP_HOST_IP + '/api/getmaxid');
      const maxId = response.data?.maxId || 0; 
      isEdit ? setCid(data.cid) : setCid(maxId + 1);  // set next product id
      setLoadingcy(false); 
    } catch (error) {
      console.error('Axios Error (getMaxId): ', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchMaxIdAndSetId, 1000); // Refresh every 5 seconds

    // Initial fetch
    fetchMaxIdAndSetId();

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [isEdit, data]);


  // Function to handle updating Product data
  const handleUserDataUpdate = async () => {
    setLoading(true); // Set loading until upload all data
    createClearanceCall();
    createSiteVisitCall();
    advanceCall();

    try {
      // If in Update product, update the existing Product data, otherwise add a new Product
      isEdit ? updateUser({ cid, cName, gender, jobJole, cPhone, nKW, Units, ElecType, city, BusinessType, SystemType, CurrentType, InstallationType, note, nPanel, Price, ClearanceStatus }) : addUser({ cid, cName, gender, jobJole, cPhone, nKW, Units, ElecType, city, BusinessType, SystemType, CurrentType, InstallationType, note, nPanel, Price, ClearanceStatus });
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Cloase loading after saving data
    }
  };


  //Main Upload button
  const handleSaveButtonClick = async (e) => {
    e.preventDefault();
    alert("Sucessfully added Client..!")
    await handleUserDataUpdate();
    setIsEdit(false); // Cloase loading after saving data
  };



  const createClearanceCall = async () => {
    const payload = {
      cid: cid,
      clearanceGet: ClearanceStatus === 'Yes' ? 'Yes' : 'No',
    }
    await Axios.post(`${process.env.REACT_APP_HOST_IP}/api/addclearance`,payload);
  }
  const createSiteVisitCall = async () => {
    const payload = {
      cid: cid,
      visitGet: 'No',
    }
    await Axios.post(`${process.env.REACT_APP_HOST_IP}/api/addsitevisit`,payload);
  }
  const advanceCall = async () => {
    const payload = {
      cid: cid,
      advanceGet: 'No',
    }
    await Axios.post(`${process.env.REACT_APP_HOST_IP}/api/addadvance`,payload);
  }
  
  const calculateUnits = (kw) => {
    if(kw<3){
      return 240
    }
    else if(kw<4){
      return 360
    }
    else if(kw<5){
      return 480
    }
    else if(kw<6){
      return 638
    }
    else if(kw<8){
      return 720
    }
    else if(kw<10){
      return 960
    }
    else if(kw<12){
      return 1200
    }
    else if(kw<15){
      return 1440
    }
    else if(kw<20){
      return 1920
    }
    else if(kw<25){
      return 2400
    }
    else if(kw<30){
      return 3120
    }
    else if(kw<40){
      return 3600
    }
    else if(kw<50){
      return 4800
    }
    else if(kw<60){
      return 6000
    }
    else if(kw<70){
      return 7200
    }
    else if(kw<80){
      return 8400
    }
    else if(kw<90){
      return 9600
    }
    else if(kw<100){
      return 10800
    }
    else if(kw<200){
      return 12000
    }
    else if(kw<300){
      return 24000
    }
    else if(kw<400){
      return 36000
    }
    else if(kw<500){
      return 48000
    }
    else if(kw<600){
      return 60000
    }
    else if(kw<700){
      return 72000
    }
    else if(kw<800){
      return 84000
    }
    else if(kw<900){
      return 96000800
    }
    else if(kw<1000){
      return 108000
    }
    else if(kw>=1000){
      return 120000
    }
  };

  const calculateKW = (Units) => {
    if(Units<=240){
      return 2
    }
    else if(Units<=360){
      return 3
    }
    else if(Units<=480){
      return 4
    }
    else if(Units<=638){
      return 5
    }
    else if(Units<=720){
      return 6
    }
    else if(Units<=960){
      return 8
    }
    else if(Units<=1200){
      return 10
    }
    else if(Units<=1440){
      return 12
    }
    else if(Units<=1920){
      return 15
    }
    else if(Units<=2400){
      return 20
    }
    else if(Units<=3120){
      return 25
    }
    else if(Units<=3600){
      return 30
    }
    else if(Units<=4800){
      return 40
    }
    else if(Units<=6000){
      return 50
    }
    else if(Units<=7200){
      return 60
    }
    else if(Units<=8400){
      return 70
    }
    else if(Units<=9600){
      return 80
    }
    else if(Units<=10800){
      return 90
    }
    else if(Units<=12000){
      return 100
    }
    else if(Units<=24000){
      return 200
    }
    else if(Units<=36000){
      return 300
    }
    else if(Units<=48000){
      return 400
    }
    else if(Units<=60000){
      return 500
    }
    else if(Units<=72000){
      return 600
    }
    else if(Units<=84000){
      return 700
    }
    else if(Units<=96000){
      return 800
    }
    else if(Units<=108000){
      return 900
    }
    else if(Units<=120000){
      return 1000
    }
    else if(Units>120000){
      return 1000
    }
  };

  return (
    <div>

      {loadingcy ? (
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress size={150} />
         </div>
      ) : (
        <>

    <div id="formStart1">
        <h2 className='topicTitle'>{isEdit ? "Update Client Record" : "Add Client Record"}</h2>
        <form>
        <div className='containerdd'>
          <div className='left-box'>
          <div className="input-group">
            <label htmlFor="employeeId" className='itemRow'>Client ID</label>
            <input 
              type="number" 
              id="cid" 
              name="cid"
              value={cid} 
              onChange={(e) => setCid(e.target.value)}
              readOnly  
              className="GetItemDetails1"
            />
          </div>

          <div className="input-group">
            <label htmlFor="employeeName" className='itemRow'>Client Name</label>
            <input 
              type="text" 
              id="cName" 
              name="cName"
              value={cName} 
              onChange={(e) => setcName(e.target.value)}
              placeholder="Enter Client Name"
              required
              className='GetItemDetails1'
            />
          </div>

          <div className="input-group">
            <label htmlFor="gender" className='itemRow'>Gender</label>
            <select
              id="gender"
              name="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
              className='GetItemDetails1'
            >
              <option value="" disabled>Select Gender</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="employeeMobile" className='itemRow'>Client Mobile Phone</label>
            <input 
              type="tel" 
              id="cPhone" 
              value={cPhone} 
              onChange={(e) => {
                const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                if (input.length <= 10) {
                  setcPhone(input);
                }
              }} 
              onBlur={() => {
                if (cPhone.length !== 10) {
                  alert("Phone number must be 10 digits");
                }
              }}
              placeholder="Enter Client Mobile Number (10 digits)"
              required
              className='GetItemDetails1'
              pattern="\d{10}"
              title="Phone number must be 10 digits"
            />
          </div>

          <div className="input-group">
            <label htmlFor="employeekw" className='itemRow'>Number of KW</label>
            <input 
              type="number" 
              id="kw" 
              value={nKW} 
              onChange={(e) => {
                const kw = Number(e.target.value);
                setnKW(kw);
                setUnits(calculateUnits(kw));
              }} 
              placeholder="Enter Number of KW"
              required
              className='GetItemDetails1'
              onWheel={(e) => e.target.blur()}
            />
          </div>

          <div className="input-group">
            <label htmlFor="employeekw" className='itemRow'>Number of Units</label>
            <input 
              type="number" 
              id="Units" 
              value={Units} 
              onChange={(e) => {
                const unit = Number(e.target.value);
                setUnits(unit);
                setnKW(calculateKW(unit));
              }} 
              placeholder="Enter Number of Units"
              required
              className='GetItemDetails1'
              onWheel={(e) => e.target.blur()}
            />
          </div>

          <div className="input-group">
            <label htmlFor="city" className='itemRow'>City</label>
            <input 
              type="text" 
              id="clientcity" 
              value={city} 
              onChange={(e) => setCity(e.target.value)} 
              placeholder="Enter Client City"
              required
              className='GetItemDetails1'
            />
          </div>

          <div className="input-group">
            <label htmlFor="nPanel" className='itemRow'>Price</label>
            <input 
              type="number" 
              id="nPanel" 
              value={Price} 
              onChange={(e) => setPrice(e.target.value)} 
              placeholder="Enter Price"
              required
              className='GetItemDetails1'
              onWheel={(e) => e.target.blur()}
            />
          </div>

          </div>

          <div className='right-box'>
          {/* Electricity Type Dropdown */}
          <div className="input-group">
                <label htmlFor="ElecType" className="itemRow">Electricity Type</label>
                <select
                  id="ElecType"
                  value={ElecType}
                  onChange={(e) => setElecType(e.target.value)}
                  className="GetItemDetails1"
                  required
                >
                  <option value="" disabled>Select Electricity Type</option>
                  <option value="CEB">CEB</option>
                  <option value="LECO">LECO</option>
                </select>
              </div>


          {/* BusinessType Dropdown */}
          <div className="input-group">
                <label htmlFor="businessType" className="itemRow">Business Type</label>
                <select
                  id="businessType"
                  value={BusinessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  className="GetItemDetails1"
                  required
                >
                  <option value="" disabled>Select Business Type</option>
                  <option value="Domestic">Domestic</option>
                  <option value="Commercial">commercial</option>
                </select>
              </div>

              {/* SystemType Dropdown */}
              <div className="input-group">
                <label htmlFor="systemType" className="itemRow">System Type</label>
                <select
                  id="systemType"
                  value={SystemType}
                  onChange={(e) => setSystemType(e.target.value)}
                  className="GetItemDetails1"
                  required
                >
                  <option value="" disabled>Select System Type</option>
                  <option value="On-grid">On-grid</option>
                  <option value="Off-grid">Off-grid</option>
                  <option value="Hybrid">Hybrid</option>
                </select>
              </div>

              {/* CurrentType Dropdown */}
              <div className="input-group">
                <label htmlFor="currentType" className="itemRow">Current Type</label>
                <select
                  id="currentType"
                  value={CurrentType}
                  onChange={(e) => setCurrentType(e.target.value)}
                  className="GetItemDetails1"
                  required
                >
                  <option value="" disabled>Select Current Type</option>
                  <option value="Single-Phase">Single-Phase</option>
                  <option value="Three-Phase">Three-Phase</option>
                </select>
              </div>


              {/* InstallationType Dropdown */}
              <div className="input-group">
                <label htmlFor="InstallationType" className="itemRow">Installation Type</label>
                <select
                  id="InstallationType"
                  value={InstallationType}
                  onChange={(e) => setInstallationType(e.target.value)}
                  className="GetItemDetails1"
                  required
                >
                  <option value="" disabled>Select Installation Type</option>
                  <option value="Semi-German">Semi-German</option>
                  <option value="Full-German">Full-German</option>
                </select>
              </div>

              <div className="input-group">
            <label htmlFor="nPanel" className='itemRow'>No of Panels</label>
            <input 
              type="number" 
              id="nPanel" 
              value={nPanel} 
              onChange={(e) => setNPanel(e.target.value)} 
              placeholder="Enter No.of Panels"
              required
              className='GetItemDetails1'
              onWheel={(e) => e.target.blur()}
            />
          </div>

              <div className="input-group">
                <label htmlFor="clearanceStatus" className="itemRow">Clearance Status</label>
                <select
                  id="clearanceStatus"
                  value={ClearanceStatus}
                  onChange={(e) => setClearanceStatus(e.target.value)}
                  className="GetItemDetails1"
                  required
                >
                  <option value="" disabled>Select Clearance Status</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>

              <div className="input-group">
            <label htmlFor="city" className='itemRow'>Job Role</label>
            <input 
              type="text" 
              id="clientcity" 
              value={jobJole} 
              onChange={(e) => setJobJole(e.target.value)} 
              placeholder="Enter Job role"
              required
              className='GetItemDetails1'
            />
          </div>

              </div>
              </div>
              <div className='middle-box'>
          <div className="input-group">
          <label htmlFor="note" className='itemRow'>Special Note</label>
            <textarea 
                id="specialnote" 
                name="firstServiceRemark" 
                className="textarea-field1" 
                placeholder="Enter Special note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
            ></textarea>
            </div>
            </div>

          

          <div className="center-button">
              <button
                  className="button"
                  onClick={handleSaveButtonClick}
                >
                  {isEdit ? "Update Record" : "Add Record"}
                </button>
            </div>

            <div style={{ color: "#000000", marginTop:"20px" }}>
                {loading && <LinearProgress />}
            </div>
        </form>

        
      </div>
      
      </>
      )}
      </div>
  );
};

export default AdminHome;