import React, { useState, useEffect } from 'react';
import './CSS/ReviewPage.css';
import { useParams } from 'react-router-dom';
import logo from '../image/feedback_logo.png';
import Axios from 'axios';

const ReviewPage = () => {
  const { cid, encode, EmpId } = useParams();
  const [selectedRating, setSelectedRating] = useState(0);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state

  useEffect(() => {
    if (encode && encode.length === 36) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  }, [encode]);

  const handleStarClick = (rating) => {
    setSelectedRating(rating);
  };

  const handleSubmit = () => {
    setLoading(true); // Start the loading
    UpdateEmpRate();
    UpdateAdminTotalRate();
  };

  const UpdateEmpRate = () => {
    const payload = {
      cid: cid,
      cRate: selectedRating,
    };

    Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclient', payload)
      .then((response) => {
        setPopup();
      })
      .catch((error) => {
        console.error('Axios Error: ', error);
      })
      .finally(() => {
        setLoading(false); // Stop loading when operation is complete
      });
  };

  const UpdateAdminTotalRate = async () => {
    try {
      const EmpResponse = await Axios.get(process.env.REACT_APP_HOST_IP + `/api/selectedemployee?eId=${EmpId}`);
      const ExistingRate = EmpResponse.data.response.totRates;

      const Total = ExistingRate + selectedRating;

      const payload = {
        eId: EmpId,
        totRates: Total,
      };

      await Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateemployee', payload);
    } catch (error) {
      console.error('Axios Error: ', error);
    }
  };

  const setPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      // Try to close the tab
      window.location.href = 'about:blank';
    }, 1000);
  };

  const getSatisfactionMessage = (rating) => {
    switch (rating) {
      case 1:
        return 'We are sorry to hear that you had a bad experience.';
      case 2:
        return 'We appreciate your feedback and will strive to improve.';
      case 3:
        return 'Thank you for your feedback. We are working on it.';
      case 4:
        return 'Glad you had a good experience!';
      case 5:
        return 'Thank you for the excellent rating!';
      default:
        return '';
    }
  };

  if (isInvalid) {
    return (
      <div className="error-message">
        <h2>The page cannot be displayed.</h2>
      </div>
    );
  }

  return (
    <div className="review-container">
      <img src={logo} alt="Archerprime Logo" className="company-logo" />
      <h2>Rate Your Experience</h2>
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <Star
            key={star}
            star={star}
            selected={star <= selectedRating}
            onClick={() => handleStarClick(star)}
          />
        ))}
      </div>
      <button className="submit-button" onClick={handleSubmit} disabled={loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </button>
      {showPopup && (
        <div className="popup">
          <p>Thank you for your feedback!</p>
        </div>
      )}
      {selectedRating > 0 && (
        <div className="rating-result">
          <p>{getSatisfactionMessage(selectedRating)}</p>
        </div>
      )}
      {loading && (
        <div className="loading-bar">
          <p>Loading...</p>
        </div>
      )}
      <footer className="footer-rate">
        <p>Thank you for your feedback! Archerprime International (Pvt) Ltd</p>
      </footer>
    </div>
  );
};

const Star = ({ star, selected, onClick }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    onClick();
    setTimeout(() => setIsClicked(false), 600); // Reset animation after 600ms
  };

  return (
    <span
      onClick={handleClick}
      className={`star ${isClicked ? 'clicked' : ''}`}
      style={{ color: selected ? 'gold' : 'gray' }}
    >
      {selected ? '★' : '☆'}
    </span>
  );
};

export default ReviewPage;
