import React, { useState } from 'react';
import { Paper, TableContainer, CircularProgress } from "@mui/material";
import './CSS/ProfileDisplay.css';

const ProfileDisplay = ({ rows, selectedUser, deleteUser, loadingcy }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchBarFocused, setSearchBarFocused] = useState(false);

  // Handle update button click Function
  const handleUpdateButtonClick = (eId) => {
    const selectedUserData = rows.find(row => row.eId === eId);
    selectedUser(selectedUserData);
  };

  // Sort rows by eId in ascending order initially
  const sortedRows = [...rows].sort((a, b) => a.eId - b.eId);

  // Filter and sort rows based on the search term
  const visibleRows = sortedRows.filter(row => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return (
      row.eId.toString().toLowerCase().includes(searchTermLowerCase) ||
      row.eName.toLowerCase().includes(searchTermLowerCase)
    );
  });

  return (
    <div className="admin-display-container">
      {loadingcy ? (
        // Display CircularProgress until getting the details from MongoDB
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress size={150} />
        </div>
      ) : (
        <>
          {/* Search bar */}
          <input
            type="text"
            className="admin-search-input5"
            placeholder="Search by Employee ID or Name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm state
            onFocus={() => setSearchBarFocused(true)}
            onBlur={() => setSearchBarFocused(false)}
          />

          {/* Table Titles */}
          <TableContainer component={Paper}>
            <table className="admin-table">
              <thead>
                <tr>
                  <th>Employee ID</th>
                  <th>Employee Name</th>
                  <th>Employee Email</th>
                  <th>Employee Mobile</th>
                  <th>Employee Password</th>
                  <th>Total Records</th>
                  <th>Total Rates</th>
                  <th>Rate Average</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Display filtered rows based on search term */}
                {visibleRows.map((row) => (
                  <tr key={row.eId}>
                    <td>{row.eId}</td>
                    <td>{row.eName}</td>
                    <td>{row.eEmail}</td>
                    <td>{row.ePhone}</td>
                    <td>{row.ePassword}</td>
                    <td>{row.totRecords}</td>
                    <td>{row.totRates}</td>
                    <td>{(row.totRates / row.totRecords).toFixed(2)}</td>
                    <td>
                      <button
                        onClick={() => handleUpdateButtonClick(row.eId)}
                        className="admin-update-button">Update
                      </button>
                      <button
                        onClick={() => deleteUser({ eId: row.eId })}
                        className="admin-delete-button">Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default ProfileDisplay;
