import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import UserForm from './clientForm';
import AdminDisplay from './ClientDetails';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Foot from '../footer';
import './CSS/navi.css';
import NaviMain from '../NavigationMain';
import './CSS/ServiceHome.css';
import SendSMS from '../Review/SendSMS';
import { Paper, Button, TableContainer, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Checkbox } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import './CSS/NotificationDisplay.css';

const UserProfile = () => {
  // Create useStates
  const [users, setUsers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const navigate = useNavigate()
  const [loadingcy, setLoadingcy] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); // State for dialog open/close
  const [loadingNotifications, setLoadingNotifications] = useState(false); // Updated state for loading notifications

  const userEmail = sessionStorage.getItem('userEmail');

  useEffect(() => {
    if (!userEmail) {
      navigate('/login');
    } else {
      getUsers();
    }
  }, [userEmail, navigate]);

  const EmpID = sessionStorage.getItem('EmpID');
  const EmpName = sessionStorage.getItem('EmpName');
  //Getting Clients using url
  const getUsers = () => {
      const getAdminDetails = () => {
        Axios.get(process.env.REACT_APP_HOST_IP + `/api/selectedemp?empId=${EmpID}`)
          .then((response) => {
            setUsers(response.data?.response || []);
            setLoadingcy(false); 
          })
          .catch((error) => {
            console.error('Axios Error: ', error);
          });
      };
      getAdminDetails();
      const intervalId = setInterval(getAdminDetails, 1000);  //Update Getting Details every 1 second
      return () => clearInterval(intervalId);
  };


  // Add Client in the CRM system
  const addUser = (data) => {
    setSubmitted(true);
    setIsEdit(false);

    // create Payload for the create new Client 
    const payload = {
      cid: data.cid,
      cName: data.cName,
      gender: data.gender,
      jobJole: data.jobJole,
      regDate: Date(),
      cPhone: data.cPhone,
      nKW: data.nKW,
      Units: data.Units,
      ElecType: data.ElecType,
      city: data.city,
      BusinessType: data.BusinessType,
      SystemType: data.SystemType,
      CurrentType: data.CurrentType,
      InstallationType: data.InstallationType,
      note: data.note,
      nPanel: data.nPanel,
      Price: data.Price,
      cRate: 0,
      empId: EmpID,
      empName: EmpName,
      sendPDF: 'no',
      pdfStatus: 'no',
      ClearanceStatus: data.ClearanceStatus
    };

    // Create new Client using url
    Axios.post(process.env.REACT_APP_HOST_IP + '/api/createclient', payload)
      .then((response) => {
        getUsers();
        setSubmitted(false);
        setDisplayTable(true);
        IncreaseRecord();
        SendSMS(data.cid, EmpID, data.cPhone).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error('Axios Error: ', error);
      });
  };


  const IncreaseRecord = async () => {
    const EmpResponse = await Axios.get(process.env.REACT_APP_HOST_IP + `/api/selectedemployee?eId=${EmpID}`);
    const ExistingRecord = EmpResponse.data.response.totRecords;

    const TotalRecord = ExistingRecord+1;

    const payload = {
      eId: EmpID,
      totRecords: TotalRecord,
    };

     Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateemployee', payload)
          .then((response) => {
          })
          .catch((error) => {
              console.error('Axios Error: ', error);
          });
  }


  // Update client details
  const updateUser = (data) => {
    setSubmitted(true);
    setIsEdit(false);

    // create Payload for the Update Client details
    const payload = {
      cid: data.cid,
      cName: data.cName,
      gender: data.gender,
      jobJole: data.jobJole,
      regDate: data.regDate,
      cPhone: data.cPhone,
      nKW: data.nKW,
      Units: data.Units,
      ElecType: data.ElecType,
      city: data.city,
      BusinessType: data.BusinessType,
      SystemType: data.SystemType,
      CurrentType: data.CurrentType,
      InstallationType: data.InstallationType,
      note: data.note,
      nPanel: data.nPanel,
      Price: data.Price,
      cRate: data.cRate,
      empId: data.empId,
      empName: data.empName, 
      ClearanceStatus: data.ClearanceStatus
  };

    // Update existing client using this url
    Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclient', payload)
      .then((response) => {
        getUsers();
        setSubmitted(false);
        setDisplayTable(true);
        // Refresh the page after successful update
        window.location.reload();
      })
      .catch((error) => {
        console.error('Axios Error: ', error);
      });
  };

  // Delete existing client using this url
  const deleteUser = (data) => {
    alert("Do you want to delete this Employee ..!")
    Axios.post(process.env.REACT_APP_HOST_IP + '/api/deleteclient', data)
      .then((response) => {
        getUsers();
      })
      .catch((error) => {
        console.error('Axios Error: ', error);
      });
  };

  // Display all client button set
  const handleDisplayButtonClick = () => {
    setDisplayTable(true);
    setSelectedUser({});
    setIsEdit(false);
  };



  const handleClickOpen = () => {
    setLoadingNotifications(true); // Set loading to true when opening the dialog
    fetchNotifications();
    setOpen(true); // Open the dialog
};

const handleClose = () => {
    setOpen(false); // Close the dialog
};

const handleCheckboxChange = async (notification) => {
    try {
        // Call the update notification API
        await Axios.post(process.env.REACT_APP_HOST_IP + '/api/updatenotification', {
            nid: notification.nid,
            nStatus: !notification.nStatus // Toggle the status
        });
        // Update the local state to reflect the change
        setNotifications(prevNotifications => 
            prevNotifications.map(n => 
                n.nid === notification.nid ? { ...n, nStatus: !n.nStatus } : n
            )
        );
    } catch (error) {
        console.error("Error updating notification status:", error);
    }
};

const fetchNotifications = async () => {
  setLoadingNotifications(true); // Set loading to true before fetching
  try {
    const response = await Axios.get(process.env.REACT_APP_HOST_IP + `/api/selectednotificationsemp?empId=${EmpID}`);
    const sortedNotifications = response.data.response.sort((a, b) => b.nid - a.nid);
    setNotifications(sortedNotifications);
    console.log(sortedNotifications);
  } catch (error) {
    console.error("Error fetching notifications:", error);
  } finally {
    setLoadingNotifications(false); // Set loading to false after fetching
  }
};

  // Start return
  return (
    <div class="container">

{/* Product Manager Navigation bar */}
    <div class="menu-body">
      <NaviMain />
      <nav>
        <ul className="nav-bar-1">
          <span className="menu">
            <li><a href="/addClient">Add Client</a></li>
            <li><a href="#" onClick={handleDisplayButtonClick}>Display Details</a></li>
          </span>
          <IconButton onClick={handleClickOpen}>
                {/* <NotificationsIcon /> */}
            </IconButton>
        </ul>
      </nav>
    </div>
      
    <Box sx={{margin: 'auto', marginTop: '30px' }}>
      
      {displayTable ? (
        <AdminDisplay  // Call all product items display page
          rows={users} //Pass all product details getting in the api
          selectedUser={(user) => {  
            setSelectedUser(user);
            setIsEdit(true);
            setDisplayTable(false);
          }}
          deleteUser={deleteUser}
          loadingcy={loadingcy}
        />
      ) : (
        <div>
          <UserForm  // Call all Add or Update items form page
            addUser={addUser}
            updateUser={updateUser}
            submitted={submitted}
            data={selectedUser}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </div>
      )}
    </Box>

   

    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ backgroundColor: '#3f51b5', color: '#fff' }}>All Notifications</DialogTitle>
      <DialogContent style={{ padding: '20px' }}>
        {loadingNotifications ? ( // Check if loadingNotifications is true
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
            <p style={{ marginLeft: '10px' }}>Fetching notifications, please wait...</p>
          </div>
        ) : (
          <div className="notification-container">
            {notifications.map(notification => (
              <Paper key={notification.cid} className="notification-card" style={{ margin: '10px 0', padding: '15px', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', width: '600px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ margin: 0, fontSize: '1.2em' }}>{notification.message}</h2>
                  <span style={{ fontWeight: 'bold', color: '#3f51b5' }}>{notification.nid}</span>
                </div>
                <p style={{ margin: '5px 0' }}>{notification.cid} - {notification.cName}</p>
                <p style={{ margin: '5px 0' }}>
                  <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      {/* Link - <a href={`${process.env.REACT_APP_HOST_IP_FRONT}/ClearanceCalling/${notification.cid}`} target="_blank" rel="noopener noreferrer" onClick={() => handleCheckboxChange(notification)}>click here..!</a> */}
                    </div>
                    <Checkbox 
                      checked={notification.nStatus!=='no'} 
                      onChange={() => handleCheckboxChange(notification)} // Handle checkbox change
                    />
                  </span>
                </p>
                <p style={{ fontSize: '0.9em', color: '#888' }}>{new Date(notification.nDate).toLocaleString()}</p>
              </Paper>
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end', padding: '10px 20px' }}>
        <Button onClick={handleClose} variant="contained" color="primary">Close</Button>
      </DialogActions>
    </Dialog>



{/* Call footer */}
    <div>
        <Foot/>
      </div>
    </div>
  );
};

export default UserProfile;
