import React from 'react';
import './cssMain/Footer.css';
import logo from './image/logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="frow">
          <div className="fcol">
            <img src={logo} className="flogo" alt='company logo'/>
            <p>
              On the other hand, we denounce with dummy righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure momo.
            </p>
          </div>

          <div className="fcol">
            <h3>Office</h3>
            <p className="fp">No.556, </p>
            <p className="fp">Bangalawa Junction 10250 Pita Kotte, </p>
            <p className="fp">Sri Lanka </p>
            <p className="fp">inquiry@archerprime.lk </p>
            <h4>+94 112 875 588 </h4>
            <h4>Mon – Fri: 8.30am To 5.30pm</h4>
          </div>

          <div className="fcol fcol3">
            <ul>
              <li><h3>Useful Links</h3></li>
              <li><a href="#">Home </a></li>
              <li><a href="#">Services </a></li>
              <li><a href="#">About Us </a></li>
              <li><a href="#">Features </a></li>
              <li><a href="#">Contacts </a></li>
            </ul>
          </div>

          <div className="fcol fcol3">
            <ul>
              <li><h3>Social Media</h3></li>
              <li><a href="#"><FontAwesomeIcon icon={faFacebook} /> Facebook</a></li>
              <li><a href="#"><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li>
              <li><a href="#"><FontAwesomeIcon icon={faLinkedin} /> Linkedin</a></li>
              <li><a href="#"><FontAwesomeIcon icon={faTiktok} /> Tiktok</a></li>
              <li><a href="#"><FontAwesomeIcon icon={faYoutube} /> Youtube</a></li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
