import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';



import Login from './profile/login';
import UserProfile from './profile/UserProfile';
import AddClient from './Employee/AddClient';
import ClientAdmin from './AdminAll/ClientAdmin';
import BranchAdmin from './AdminAll/BranchAdmin';
import ReviewPage from './Review/UserFeedback';
import MyProfile from './Employee/EmployeeProfile';
// import SendingSMS from './Review/sendingSMS';
import PdfGeneration from './Employee/pdfGeneration';

import AllRecords from './FbRecords/AllRecords';
import EmployeeRecords from './FbRecords/EmployeeRecords';



import ClearanceCalling from './Clearance/ClearanceCalling';
import SiteVisitCalling from './SiteVisits/SiteVisitCalling';
import AdvanceCalling from './Advance/AdvanceCalling';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
        <Route path='/' element={<App />} />
        <Route path='/login' element={<Login />} />
        <Route path='/profile' element={<UserProfile />} />
        <Route path='/addClient' element={<AddClient />} />
        <Route path='/ClientAdmin' element={<ClientAdmin />} />
        <Route path='/BranchAdmin' element={<BranchAdmin />} />
        <Route path='/MyProfile' element={<MyProfile />} />

        <Route path='/PdfGeneration' element={<PdfGeneration />} />
        <Route path='/ReviewPage/:cid/:encode/:EmpId' element={<ReviewPage />} />
        {/* <Route path='/SendingSMS' element={<SendingSMS />} /> */}

        <Route path='/AllRecords' element={<AllRecords />} />
        <Route path='/EmployeeRecords' element={<EmployeeRecords />} />

        <Route path='/ClearanceCalling/:cid' element={<ClearanceCalling />} />
        <Route path='/SiteVisitCalling/:cid' element={<SiteVisitCalling />} />
        <Route path='/AdvanceCalling/:cid' element={<AdvanceCalling />} />

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
