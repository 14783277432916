import React from 'react';
import './Dashboard.css';
import { useNavigate } from "react-router-dom";

const App = () => {
  const navigate = useNavigate();

  const profileManagement = () => {
    navigate('/login');
  }

  return (
    <div className="dashboard-container">
      <div className="background-blur"></div>
      <div className="welcome-message">
        <h1>Welcome to</h1>
        <h1>Customer Call Management System</h1>
      </div>
      <button className="box" onClick={() => profileManagement()}>
        <h2 className='box-name'>Get Started</h2>
      </button>
    </div>
  );
};

export default App;
