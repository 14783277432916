import React, { useState, useEffect } from 'react';
import './AdvanceCalling.css';
import { useParams, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import NaviMain from '../NavigationMain'
import Foot from '../footer';
import Switch from '@mui/material/Switch';


const AdvanceCalling = () => {
  const { cid} = useParams();


  const [advanceGetDate, setAdvanceGetDate] = useState('');
  const [advanceGet, setAdvanceGet] = useState('');
  const [fAdvanceCallRemark, setFAdvanceCallRemark] = useState('');
  const [sAdvanceCallRemark, setSAdvanceCallRemark] = useState('');
  const [tAdvanceCallRemark, setTAdvanceCallRemark] = useState('');

  const [fAdvanceCallDate, setFAdvanceCallDate] = useState('');
  const [sAdvanceCallDate, setSAdvanceCallDate] = useState('');
  const [tAdvanceCallDate, setTAdvanceCallDate] = useState('');

  const [isServiceAdded, setIsServiceAdded] = useState({
      fs: false,
      ss: false,
      ts: false
  });

  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

    const userEmail = sessionStorage.getItem('userEmail');
  
    useEffect(() => {
      if (!userEmail) {
        navigate('/login');
      } else {
        fetchServiceData();
      }
    }, [cid, userEmail, navigate]);

    const fetchServiceData = () => {
        if (cid) {
            Axios.get(process.env.REACT_APP_HOST_IP + `/api/advances?cid=${cid}`)
                .then((response) => {
                    if (response.data.response) {
                        const { advanceGet, advanceGetDate, fAdvanceCallRemark, sAdvanceCallRemark, tAdvanceCallRemark, fAdvanceCallDate, sAdvanceCallDate, tAdvanceCallDate } = response.data.response;
                        setAdvanceGet(advanceGet || 'No')
                        setAdvanceGetDate(advanceGetDate || '');
                        setFAdvanceCallRemark(fAdvanceCallRemark || '');
                        setSAdvanceCallRemark(sAdvanceCallRemark || '');
                        setTAdvanceCallRemark(tAdvanceCallRemark || '');

                        setFAdvanceCallDate(fAdvanceCallDate || '');
                        setSAdvanceCallDate(sAdvanceCallDate || '');
                        setTAdvanceCallDate(tAdvanceCallDate || '');
                        // Disable button if there's an existing date
                        setIsServiceAdded({
                            fs: !!fAdvanceCallDate,
                            ss: !!sAdvanceCallDate,
                            ts: !!tAdvanceCallDate
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching client data:', error);
                });
        }
    };


    const updateFirstCall = (type) => {
    
      const payload = {
          cid: cid,
          fAdvanceCallRemark: fAdvanceCallRemark,
          fAdvanceCallDate: Date()
      };

      console.log(payload);
  
      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateadvance', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
  };

    const updateService = (type) => {
    
        const payload = {
            cid: cid,
            sAdvanceCallRemark: sAdvanceCallRemark,
            sAdvanceCallDate: Date(),
        };

        console.log(payload);
    
        Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateadvance', payload)
            .then((response) => {
                setAlert({
                    type: 'success',
                    message: 'Service updated successfully!',
                  });
                setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
                fetchServiceData(); // Fetch and update the data after adding service
            })
            .catch((error) => {
                setAlert({
                    type: 'error',
                    message: 'Service added Unsucessfull..! Try again',
                  });
                console.error('Axios Error: ', error);
            });
    };

    const UpdateThirdCall = (type) => {
      const payload = {
          cid: cid,
          tAdvanceCallRemark: tAdvanceCallRemark,
          tAdvanceCallDate: Date()
      };
  
      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateadvance', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
  };

  
    const isSectionReadOnly = (type) => !!isServiceAdded[type];
    const getInputType = (dateValue) => (dateValue ? 'text' : 'date');


    const handleClearanceToggle = async (cid, currentStatus) => {
      const newStatus = currentStatus === 'Yes' ? 'No' : 'Yes';
      try {
        // Make an API call to update the sendPDF status
        const payload = {
          cid: cid,
          advanceGet: newStatus,
          advanceGetDate: Date()
        }
        console.log(payload)
        await Axios.post(`${process.env.REACT_APP_HOST_IP}/api/updateadvance`,payload);
  
        // Update the local state
        setAdvanceGet(newStatus);
      } catch (error) {
        console.error('Error updating sendPDF status:', error);
        // Optionally, show an error message to the user
      }
    };

    const isEditable = (callDate) => {
      return !callDate || (Date.now() - new Date(callDate).getTime() < 24 * 60 * 60 * 1000); // Check if call date is less than 24 hours old
    };

    return (
      <div class="container">
          <div class="menu-body">
              <NaviMain />
          </div>
      
      <div className="service-form-container">
          <h2>Advance Calling</h2>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Switch
              checked={advanceGet === 'Yes'}
              onChange={() => handleClearanceToggle(cid, advanceGet)}
              color="primary"
            />
            <span style={{ marginLeft: '10px' }}>
              {advanceGet === 'Yes' ? 'Advance Obtained' : 'Advance Pending'}
            </span>
          </div>

          {/* Add this new section to display the clearance get date */}
          {advanceGetDate && (
            <div style={{ marginBottom: '20px' }}>
              <strong>Advance Get Date:</strong> {new Date(advanceGetDate).toLocaleString()}
            </div>
          )}

          <form>
              {/* Client ID Section */}
              <div className="form-section">
                  <label htmlFor="clientId">Client ID</label>
                  <input 
                      type="text" 
                      id="clientId" 
                      name="clientId" 
                      className="input-field" 
                      value={cid}
                      readOnly 
                  />
              </div>

              {/* First Service Section */}
              <div className={`form-section ${isSectionReadOnly('fs') ? 'read-only' : ''}`}>
                  <h3>First Advance Call</h3>
                  
                  <label htmlFor="firstServiceRemark">1st Call Remark</label>
                  <textarea 
                      id="firstServiceRemark" 
                      name="firstServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={fAdvanceCallRemark}
                      onChange={(e) => setFAdvanceCallRemark(e.target.value)}
                      readOnly={!isEditable(fAdvanceCallDate)}
                  ></textarea>
                  {fAdvanceCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(fAdvanceCallDate).toLocaleString()}
                    </div>
                  )}
                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => updateFirstCall('fs')}
                      disabled={!isEditable(fAdvanceCallDate)}
                  >
                      Add 1st Call
                  </button>
              </div>



              {/* Second Service Section */}
              <div className={`form-section ${isSectionReadOnly('ss') ? 'read-only' : ''}`}>
                  <h3>Second Advance Call</h3>
                  
                  <label htmlFor="secondServiceRemark">2nd Call Remark</label>
                  <textarea 
                      id="secondServiceRemark" 
                      name="secondServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={sAdvanceCallRemark}
                      onChange={(e) => setSAdvanceCallRemark(e.target.value)}
                      readOnly={!isEditable(sAdvanceCallDate)}
                  ></textarea>

                  {sAdvanceCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(sAdvanceCallDate).toLocaleString()}
                    </div>
                  )}
                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => updateService('ss')}
                      disabled={!isEditable(sAdvanceCallDate)}
                  >
                      Add 2nd Call  
                  </button>
              </div>


              {/* Third Service Section */}
              <div className={`form-section ${isSectionReadOnly('ts') ? 'read-only' : ''}`}>
                  <h3>Third Advance Call</h3>

                  <label htmlFor="thirdServiceRemark">3rd Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={tAdvanceCallRemark}
                      onChange={(e) => setTAdvanceCallRemark(e.target.value)}
                      readOnly={!isEditable(tAdvanceCallDate)}
                  ></textarea>
                  {tAdvanceCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(tAdvanceCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateThirdCall('ts')}
                      disabled={!isEditable(tAdvanceCallDate)}
                  >
                      Add 3rd Call
                  </button>
              </div>

          </form>
      </div>

      {alert && (
          <div className={`alert-box ${alert.type}`}>
            {alert.message}
            <button onClick={() => setAlert(null)} className="alert-dismiss-button">OK</button>
          </div>
        )}

      <div>
      <Foot/>
    </div>
      </div>
  );
};

export default AdvanceCalling;
