import React, { useState, useEffect } from 'react';
import './CSS/ProfileDisplay.css'; // Assuming CSS is the same
import { Paper, Button, TableContainer, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
//import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { Box } from '@mui/material';
import NaviMain from '../AdminNavi';
import './CSS/navi.css';
import './CSS/ServiceHome.css';
import Foot from '../footer';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import logo from '../image/logo.jpg'
import { storage } from '../firebase'; // Import your Firebase storage configuration
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import * as XLSX from 'xlsx'; // Import the XLSX library for Excel file generation

const AdminTable = () => {
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSiteVisitedCategory, setSiteVisitCategory] = useState([]);
  const [selectedClearanceCategory, setClearanceCategory] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingcy, setLoadingcy] = useState(true);
  const navigate = useNavigate()
  const [selectedSeeMore, setSelectedSeeMore] = useState(null);
  const [selectedClearance, setSelectedClearance] = useState(null);
  const [openSeeMoreDialog, setOpenSeeMoreDialog] = useState(false);
  const [openClearanceDialog, setOpenClearanceDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState('');


  const [clearanceName, setClearanceName] = useState('');
  const [clearanceAddress, setClearanceAddress] = useState('');
  const [clearanceCapacity, setClearanceCapacity] = useState('');
  const [pdfLink, setPdfLink] = useState('');
  
  const [open, setOpen] = useState(false);
  const [loadingSeeMore, setLoadingSeeMore] = useState({}); // Change to an object to track loading for each record
  const [loadingImage, setLoadingImage] = useState(false); // New state for loading image

  const [colorFilter, setColorFilter] = useState(''); // New state for color filter

  const userEmail = sessionStorage.getItem('userEmail');
  const password = sessionStorage.getItem('password');
  const AdminName = sessionStorage.getItem('AdminName');

  useEffect(() => {
    if (!userEmail || !password) {
      navigate('/login');
    } else {
      fetchData();
    }
  }, [userEmail, navigate]);

  const handleClickOpen = (cid) => {
    setOpen(cid); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleClearanceCall = (cid) => {
    navigate(`/ClearanceCalling/${cid}`); // Navigate to ClearanceCalling
    handleClose(); // Close the dialog
  };

  const handleSiteVisitCall = (cid) => {
    navigate(`/SiteVisitCalling/${cid}`); // Navigate to SiteVisitCalling
    handleClose(); // Close the dialog
  };


  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_HOST_IP + '/api/clients');
      setRows(response.data.response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingcy(false);
    }
  };

  useEffect(() => {
    fetchData(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000); // Fetch every 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs once on mount and sets up the interval

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  const clearDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const deleteUser = (cid) => {
    const confirmDelete = window.confirm("Do you want to delete this Employee?");
    
    if (confirmDelete) {
      axios.post(process.env.REACT_APP_HOST_IP + '/api/deleteclient', cid)
        .then((response) => {
          // Call a function to update the data here, e.g., fetching the updated list of users
          fetchData(); // This function fetches the updated data
        })
        .catch((error) => {
          console.error('Axios Error: ', error);
        });
    }
  };
  

  // Filter rows based on category and search term
  const filteredRows = Array.isArray(rows) && (selectedCategory.length === 0 ? rows : rows.filter(row => selectedCategory.includes(row.ElecType)));
  const filteredSiteRows = Array.isArray(filteredRows) && (selectedSiteVisitedCategory.length === 0 ? filteredRows : filteredRows.filter(row => selectedSiteVisitedCategory.includes(row.siteVisitStatus)));
  const filteredClearanceRows = Array.isArray(filteredSiteRows) && (selectedClearanceCategory.length === 0 ? filteredSiteRows : filteredSiteRows.filter(row => selectedClearanceCategory.includes(row.ClearanceStatus)));

  const visibleRows = Array.isArray(filteredClearanceRows) && filteredClearanceRows.filter(row => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const searchTerm2LowerCase = searchTerm2.toLowerCase();
  
    const matchesSearchTerm = row.cName.toLowerCase().includes(searchTermLowerCase) 
      || row.cid.toString().includes(searchTermLowerCase) 
      || row.city.toLowerCase().includes(searchTermLowerCase)
      || row.cPhone.toLowerCase().includes(searchTermLowerCase);
  
    const empIdString = row.empId ? row.empId.toString().toLowerCase() : '';
    const empNameString = row.empName ? row.empName.toLowerCase() : '';
    const matchesSearchTerm2 = empIdString.includes(searchTerm2LowerCase) 
      || empNameString.includes(searchTerm2LowerCase);
  
    // Ensure proper date conversion
    const rowDate = new Date(row.regDate);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
  
    const matchesDateRange = (!start || rowDate >= start) && (!end || rowDate <= end);
  
    // Check for color filter match
    const matchesColorFilter = !colorFilter || row.ColorStatus === colorFilter;
  
    return matchesSearchTerm && matchesSearchTerm2 && matchesDateRange && matchesColorFilter;
  }).sort((a, b) => b.cid - a.cid);
  
  

  const handleSeeMoreClick = async (cid, cName, gender, jobJole, formattedDate, cPhone, city, nKW, Units, ElecType, BusinessType, SystemType, CurrentType, InstallationType, note, empName, ClearanceStatus, ClearanceDate) => {
    setLoadingSeeMore(prev => ({ ...prev, [cid]: true })); // Set loading for the specific record
    let res1 = {}; // Initialize res1 as an empty object
    let res2 = {}; // Initialize res2 as an empty object
    let res3 = {};

    try {
        const clientResponse = await axios.get(process.env.REACT_APP_HOST_IP + `/api/clearance?cid=${cid}`);
        res1 = clientResponse.data.response; // Assign response if successful
    } catch (error) {
        console.error('Error fetching clearance data:', error);
        res1 = {}; // Set to empty result if API call fails
    }

    try {
        const clientResponse2 = await axios.get(process.env.REACT_APP_HOST_IP + `/api/sitevisit?cid=${cid}`);
        res2 = clientResponse2.data.response; // Assign response if successful
    } catch (error) {
        console.error('Error fetching site visit data:', error);
        res2 = {}; // Set to empty result if API call fails
    }

    try {
      const clientResponse3 = await axios.get(process.env.REACT_APP_HOST_IP + `/api/advances?cid=${cid}`);
      res3 = clientResponse3.data.response; // Assign response if successful
    } catch (error) {
        console.error('Error fetching Advance data:', error);
        res3 = {}; // Set to empty result if API call fails
    }
    
    setSelectedSeeMore({ cid, cName, gender, jobJole, formattedDate, cPhone, city, nKW, Units, ElecType, BusinessType, SystemType, CurrentType, InstallationType, note, empName, ClearanceStatus, ClearanceDate, res1, res2, res3 });
    setOpenSeeMoreDialog(true);
    setLoadingSeeMore(prev => ({ ...prev, [cid]: false })); // Reset loading for the specific record
  };

  const handleCloseSeeMoreDialog = () => {
    setOpenSeeMoreDialog(false);
  };

  const handleClearanceClick = (cid, cName, nKW) => {
    fetchImage(cid);
    setClearanceName(cName);
    setClearanceCapacity(nKW)
    setSelectedClearance({ cid, cName, nKW });
    setOpenClearanceDialog(true);
  };

  const handleCloseClearanceDialog = () => {
    setImageUrl(null);
    setOpenClearanceDialog(false);
  };


  const generatePDF = () => {
    const doc = new jsPDF();
    
    // Add company logo
    doc.addImage(logo, 'PNG', 20, 10, 30, 30); // Ensure 'logo' is imported correctly
    
    // Add company name and details
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(16);
    doc.text('Archer Prime International (Pvt)Ltd', 60, 20); // Company name next to logo
    
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.text('Solar Power Company', 60, 28);
    doc.text('Contact: info@archerprime.lk | www.archerprime.lk', 60, 35);
    
    // Add a horizontal line below the header
    doc.setLineWidth(0.5);
    doc.line(20, 45, 190, 45);
    
    // Title Section
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(18);
    doc.setTextColor(40, 40, 40);
    doc.text('Client Details Report', 105, 55, { align: 'center' });
    
    // Add another line below the title
    doc.setLineWidth(0.5);
    doc.line(20, 60, 190, 60);
    
    // Set font size and color for details
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(14);
    doc.setTextColor(60, 60, 60);
    
    // Define content for client details
    const content = [
      { label: 'Client ID:', value: selectedSeeMore.cid || 'Not Available' },
      { label: 'Client Name:', value: selectedSeeMore.cName || 'Not Available' },
      { label: 'Date:', value: selectedSeeMore.formattedDate || 'Not Available' },
      { label: 'Phone Number:', value: selectedSeeMore.cPhone || 'Not Available' },
      { label: 'City:', value: selectedSeeMore.city || 'Not Available' },
      { label: 'Number of KW:', value: selectedSeeMore.nKW || 'Not Available' },
      { label: 'Number of Units:', value: selectedSeeMore.Units || 'Not Available' },
      { label: 'Electricity Type:', value: selectedSeeMore.ElecType || 'Not Available' },
      { label: 'Business Type:', value: selectedSeeMore.BusinessType || 'Not Available' },
      { label: 'System Type:', value: selectedSeeMore.SystemType || 'Not Available' },
      { label: 'Current Type:', value: selectedSeeMore.CurrentType || 'Not Available' },
      { label: 'Installation Type:', value: selectedSeeMore.InstallationType || 'Not Available' },
      { label: 'Special Note:', value: selectedSeeMore.note || 'Not Available' },
    ];
  
    // Define start position for details
    let yPosition = 70; // Adjusted to accommodate the content above
    
    // Loop through and add each client detail to the PDF
    content.forEach((item) => {
      doc.setFont('Helvetica', 'bold');
      doc.text(item.label, 20, yPosition);
      
      doc.setFont('Helvetica', 'normal');
      
      // If the "Special Note" is too long, split it into lines of max width 100
      if (item.label === 'Special Note:' && item.value.length > 50) {
        const splitText = doc.splitTextToSize(item.value, 120); // Split to maxWidth 100
        splitText.forEach(line => {
          doc.text(line, 80, yPosition);
          yPosition += 6; // Move to the next line
        });
      } else {
        doc.text(item.value.toString(), 80, yPosition);
        yPosition += 10;
      }
    });
    
    // Add a footer
    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100);
    doc.text('Generated by Archer Prime International (Pvt)Ltd - IT Section', 105, 290, { align: 'center' });

    // Add generated date and person at the bottom
    const currentDate = new Date();
    const sriLankaTime = new Date(currentDate.getTime());
    
    const formattedDate = sriLankaTime.toLocaleDateString(); // Format the date (e.g., 'MM/DD/YYYY')
    const formattedTime = sriLankaTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }); // Format the time (24-hour format)

    const generatedPerson = userEmail;

    // Position the date and person closer to the bottom
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.text(`Generated on: ${formattedDate}, Time: ${formattedTime}`, 20, 260);
    doc.text(`Generated by: ${generatedPerson}`, 20, 268);

    // Add a new page for Clearance Status
    doc.addPage();

    const sections = [
        { title: 'Clearance Status', items: [
            { label: 'Clearance Status:', value: selectedSeeMore.ClearanceStatus === 'Yes' ? 'Get Clearance' : 'Not given Yet', yPosition: 10 },
            { label: '1st Clearance call:', value: selectedSeeMore.res1.fCallRemark || 'Not yet' },
            { label: '1st Clearance call Date:', value: selectedSeeMore.res1.fCallDate ? new Date(selectedSeeMore.res1.fCallDate).toLocaleDateString() : 'Not yet'},
            { label: '2nd Clearance call:', value: selectedSeeMore.res1.sCallRemark || 'Not yet' },
            { label: '2nd Clearance call Date:', value: selectedSeeMore.res1.sCallDate ? new Date(selectedSeeMore.res1.sCallDate).toLocaleDateString() : 'Not yet' , yPosition: 20},
            { label: '3rd Clearance call:', value: selectedSeeMore.res1.tCallRemark || 'Not yet' },
            { label: '3rd Clearance call Date:', value: selectedSeeMore.res1.tCallDate ? new Date(selectedSeeMore.res1.tCallDate).toLocaleDateString() : 'Not yet' , yPosition: 20},
            { label: '4th Clearance call:', value: selectedSeeMore.res1.fourCallRemark || 'Not yet' },
            { label: '4th Clearance call Date:', value: selectedSeeMore.res1.fourCallDate ? new Date(selectedSeeMore.res1.fourCallDate).toLocaleDateString() : 'Not yet' , yPosition: 20},
            { label: '5th Clearance call:', value: selectedSeeMore.res1.fiveCallRemark || 'Not yet' },
            { label: '5th Clearance call Date:', value: selectedSeeMore.res1.fiveCallDate ? new Date(selectedSeeMore.res1.fiveCallDate).toLocaleDateString() : 'Not yet' , yPosition: 20},
            { label: '6th Clearance call:', value: selectedSeeMore.res1.sixCallRemark || 'Not yet' },
            { label: '6th Clearance call Date:', value: selectedSeeMore.res1.sixCallDate ? new Date(selectedSeeMore.res1.sixCallDate).toLocaleDateString() : 'Not yet' , yPosition: 20}
        ] },
    ];

    yPosition = 20; 

    sections.forEach(section => {
        doc.setFont('Helvetica', 'bold');
        doc.text(section.title, 20, yPosition);
        yPosition += 10;

        section.items.forEach(item => {
            doc.setFont('Helvetica', 'normal');
            doc.text(`${item.label} ${item.value}`, 20, yPosition);
            yPosition += 8;
        });

        yPosition += 20; // Add space between sections
    });

    // Add a new page for Site Visit Status
    doc.addPage();

    const siteVisitSections = [
        { title: 'Site Visit Status', items: [
            { label: 'Site visit status:', value: selectedSeeMore.res2.visitGet === 'Yes' ? 'Got a date' : 'No date Yet' },
            { label: '1st Site Visit call:', value: selectedSeeMore.res2.fVisitCallRemark || 'Not yet' },
            { label: '1st Site Visit call Date:', value: selectedSeeMore.res2.fVisitCallDate ? new Date(selectedSeeMore.res2.fVisitCallDate).toLocaleDateString() : 'Not yet' },
            { label: '2nd Site Visit call:', value: selectedSeeMore.res2.sVisitCallRemark || 'Not yet' },
            { label: '2nd Site Visit call Date:', value: selectedSeeMore.res2.sVisitCallDate ? new Date(selectedSeeMore.res2.sVisitCallDate).toLocaleDateString() : 'Not yet' },
            { label: '3rd Site Visit call:', value: selectedSeeMore.res2.tVisitCallRemark || 'Not yet' },
            { label: '3rd Site Visit call Date:', value: selectedSeeMore.res2.tVisitCallDate ? new Date(selectedSeeMore.res2.tVisitCallDate).toLocaleDateString() : 'Not yet' },
            { label: '4th Site Visit call:', value: selectedSeeMore.res2.fourVisitCallRemark || 'Not yet' },
            { label: '4th Site Visit call Date:', value: selectedSeeMore.res2.fourVisitCallDate ? new Date(selectedSeeMore.res2.fourVisitCallDate).toLocaleDateString() : 'Not yet' },
            { label: '5th Site Visit call:', value: selectedSeeMore.res2.fiveVisitCallRemark || 'Not yet' },
            { label: '5th Site Visit call Date:', value: selectedSeeMore.res2.fiveVisitCallDate ? new Date(selectedSeeMore.res2.fiveVisitCallDate).toLocaleDateString() : 'Not yet' }
        ] },
    ];

    yPosition = 20; 

    siteVisitSections.forEach(section => {
        doc.setFont('Helvetica', 'bold');
        doc.text(section.title, 20, yPosition);
        yPosition += 10;

        section.items.forEach(item => {
            doc.setFont('Helvetica', 'normal');
            doc.text(`${item.label} ${item.value}`, 20, yPosition);
            yPosition += 8;
        });

        yPosition += 20; // Add space between sections
    });

    // Add a new page for Advance Status
    doc.addPage();

    const advanceSections = [
        { title: 'Advance Status', items: [
            { label: 'Advance Status:', value: selectedSeeMore.res3.advanceGet === 'Yes' ? 'Take an Advance' : 'Advance not taken' },
            { label: '1st Advance call:', value: selectedSeeMore.res3.fAdvanceCallRemark || 'Not yet' },
            { label: '1st Advance call Date:', value: selectedSeeMore.res3.fAdvanceCallDate ? new Date(selectedSeeMore.res3.fAdvanceCallDate).toLocaleDateString() : 'Not yet' },
            { label: '2nd Advance call:', value: selectedSeeMore.res3.sAdvanceCallRemark || 'Not yet' },
            { label: '2nd Advance call Date:', value: selectedSeeMore.res3.sAdvanceCallDate ? new Date(selectedSeeMore.res3.sAdvanceCallDate).toLocaleDateString() : 'Not yet' },
            { label: '3rd Advance call:', value: selectedSeeMore.res3.tAdvanceCallRemark || 'Not yet' },
            { label: '3rd Advance call Date:', value: selectedSeeMore.res3.tAdvanceCallDate ? new Date(selectedSeeMore.res3.tAdvanceCallDate).toLocaleDateString() : 'Not yet' }
        ] },
    ];

    yPosition = 20; 

    advanceSections.forEach(section => {
        doc.setFont('Helvetica', 'bold');
        doc.text(section.title, 20, yPosition);
        yPosition += 10;

        section.items.forEach(item => {
            doc.setFont('Helvetica', 'normal');
            doc.text(`${item.label} ${item.value}`, 20, yPosition);
            yPosition += 8;
        });

        yPosition += 20; // Add space between sections
    });

    // Save the PDF
    doc.save('client-details.pdf');
};


const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0'); // Ensures two digits for day
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const year = d.getFullYear();
  
  return `${day}/${month}/${year}`;
};

function formatDate2(dateString) {
  const [day, month, year] = dateString.split('/');
  const shortYear = year.slice(-2);
  const formattedMonth = month.padStart(2, '0');
  const formattedDay = day.padStart(2, '0');

  return `${formattedMonth}${formattedDay}${shortYear}`;
}

function getInitials(fullName) {
  const nameArray = fullName.split(' ');
  let initials = nameArray[0][0];

  if (nameArray.length > 1) {
    initials += nameArray[1][0];
  }

  return initials.toUpperCase();
}

const generateClearance = async (cid) => {
  console.log(clearanceName.toUpperCase(),clearanceAddress,clearanceCapacity)
    const refDate = formatDate2(formatDate(new Date()));
    const refName = getInitials(AdminName);

  const payload = {
    CusDate: formatDate(new Date()), 
    CusName: clearanceName.toUpperCase(), 
    CusAddress: clearanceAddress,
    Capacity:clearanceCapacity,
    InvoiceNo: refName+'-'+refDate+'-'+cid,
  };

  try {
    // Ensure responseType is 'blob' to correctly handle binary data (PDF)
    const response = await axios.post(process.env.REACT_APP_HOST_IP + '/api/generate-pdf-clearance', payload, {
      responseType: 'blob' // Important: Handle binary data
    });

    // Create a link to the PDF
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    setPdfLink(fileURL);

  } catch (error) {
    console.error('Error generating PDF:', error);
  }

};


const fetchImage = async (cid) => {
  setLoadingImage(true); // Start loading
  try {
    const imagesRef = ref(storage, `clearance_images/${cid}/`);
    const imageList = await listAll(imagesRef); // List all images in the directory
    const matchingImage = imageList.items.find(item => item.name.startsWith(cid)); // Find the first image with the cid prefix

    if (matchingImage) {
      const url = await getDownloadURL(matchingImage); // Get the download URL
      setImageUrl(url); // Set the image URL state
    } else {
      console.error('No image found with the specified prefix.');
    }
  } catch (error) {
    console.error('Error fetching image:', error);
  } finally {
    setLoadingImage(false); // Stop loading
  }
};

const downloadExcel = () => {
  // Filter rows based on the selected date range
  const filteredData = visibleRows.filter(row => {
    const rowDate = new Date(row.regDate);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    return (!start || rowDate >= start) && (!end || rowDate <= end);
  });

  // Prepare data for Excel
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Clients');

  // Generate Excel file and trigger download
  XLSX.writeFile(workbook, 'client_details.xlsx');
};

// Function to handle color filter selection
const handleColorFilter = (color) => {
  setColorFilter(color);
};

const handleCategoryChange = (category) => {
    setSelectedCategory(prev => 
        prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
};

const handleSiteVisitChange = (category) => {
    setSiteVisitCategory(prev => 
        prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
};

const handleClearanceChange = (category) => {
    setClearanceCategory(prev => 
        prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
};

  return (
    <div class="container-2">

{/* Product Manager Navigation bar */}
    <div class="menu-body">
      <NaviMain />
    </div>

    <Box sx={{ margin: 'auto', marginTop: '40px' }}>

    <div className="admin-display-container">
      {loadingcy ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress size={150} />
        </div>
      ) : (
        <>
          <div className='top-actions'>
            <div className="date-filter-group5">
              <div className="date-filter-ind">
                <label htmlFor="start-date">Start Date</label>
                <input
                  type="date"
                  id="start-date"
                  className="styled-date-input5"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="date-filter-ind">
                <label htmlFor="end-date">End Date</label>
                <input
                  type="date"
                  id="end-date"
                  className="styled-date-input5"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <button onClick={clearDateFilters} variant="outlined" color="secondary" class="clear-btn">Clear Dates</button>
              <button onClick={downloadExcel} variant="outlined" color="primary" class="download-excel-btn">Download Excel</button>
            </div>

            <input
              type="text"
              className="admin-search-input"
              placeholder="Search by Client ID or Name or City or Number..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <input
              type="text"
              className="admin-search-input-emp"
              placeholder="Search by Employee ID or Name..."
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
          </div>

          <div className="admin-button-group-admin">

            <Tooltip title="Site visit done, CEB approved and accept our deal" arrow placement="bottom">
                <button onClick={() => handleColorFilter('green')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: '#00f00b' }}></button>
            </Tooltip>
            <Tooltip title="Site visit done, CEB not approved and accept our deal" arrow placement="bottom">
                <button onClick={() => handleColorFilter('yellow')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'yellow' }}></button>
            </Tooltip>
            <Tooltip title="Maximum 3 week Delay" arrow placement="bottom">
                <button onClick={() => handleColorFilter('pink')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'pink' }}></button>
            </Tooltip>
            <Tooltip title="Maximum 4 month Delay" arrow placement="bottom">
                <button onClick={() => handleColorFilter('blue')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'blue' }}></button>
            </Tooltip>
            <Tooltip title="Rejected" arrow placement="bottom">
                <button onClick={() => handleColorFilter('red')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '30px', backgroundColor: 'red' }}></button>
            </Tooltip>
            <Tooltip title="Price Issue and Outside our system" arrow placement="bottom">
                <button onClick={() => handleColorFilter('purple')} style={{ width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', marginRight: '10px', backgroundColor: 'purple' }}></button>
            </Tooltip>
            <button onClick={() => handleColorFilter('')}  style={{ marginRight: '80px'}} >All Colors</button>

           
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="radio-group">
                    <label>
                        <input 
                            type="radio" 
                            value="All" 
                            checked={selectedCategory.length === 0} 
                            onChange={() => {
                                setSelectedCategory([]); // Clear selection when "All" is clicked
                            }} 
                        />
                        <span>All Types</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="CEB" 
                            checked={selectedCategory.includes('CEB')} 
                            onChange={() => {
                                setSelectedCategory(['CEB']); // Set selected category to CEB
                            }} 
                        />
                        <span>CEB</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="LECO" 
                            checked={selectedCategory.includes('LECO')} 
                            onChange={() => {
                                setSelectedCategory(['LECO']); // Set selected category to LECO
                            }} 
                        />
                        <span>LECO</span>
                    </label>
                </div>

                <div className="radio-group">
                    <label>
                        <input 
                            type="radio" 
                            value="All" 
                            checked={selectedSiteVisitedCategory.length === 0} 
                            onChange={() => {
                                setSiteVisitCategory([]); // Clear selection when "All" is clicked
                            }} 
                        />
                        <span>All Types</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="Yes" 
                            checked={selectedSiteVisitedCategory.includes('Yes')} 
                            onChange={() => {
                                setSiteVisitCategory(['Yes']); // Set selected category to Yes
                            }} 
                        />
                        <span>Site visited</span>
                    </label>
                </div>

                <div className="radio-group">
                    <label>
                        <input 
                            type="radio" 
                            value="All" 
                            checked={selectedClearanceCategory.length === 0} 
                            onChange={() => {
                                setClearanceCategory([]); // Clear selection when "All" is clicked
                            }} 
                        />
                        <span>All Types</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="Yes" 
                            checked={selectedClearanceCategory.includes('Yes')} 
                            onChange={() => {
                                setClearanceCategory(['Yes']); // Set selected category to Yes
                            }} 
                        />
                        <span>Clearance GET</span>
                    </label>
                </div>
            </div>
          </div>
        
          <TableContainer component={Paper}>
            <table className="admin-table">
              <thead>
                <tr>
                  <th>Client ID</th>
                  <th>Client Name</th>
                  <th>Date</th>
                  <th>Phone Number</th>
                  <th>City</th>
                  <th>Special Note</th>
                  <th>Details</th>
                  <th >Emp ID</th>
                  <th>Employee Name</th>
                  <th>Rate</th>
                  <th>Action</th>
                  
                </tr>
              </thead>
              <tbody>
                {Array.isArray(visibleRows) && visibleRows.map((row) => {
                  const formattedDate = new Date(row.regDate).toLocaleDateString();
                  return (
                    <tr key={row.cid}>
                      <td style={{ backgroundColor: row.ColorStatus === 'green' ? '#00f00b' : row.ColorStatus || '#f0f0f0' }}>{row.cid}</td>
                      <td>{row.cName}</td>
                      <td>{formattedDate}</td>
                      <td>{row.cPhone}</td>
                      <td>{row.city}</td>
                      <td><button onClick={() => handleNoteClick(row.note)} className="admin-location-button">View</button></td>
                      <td>
                        <button onClick={() => handleSeeMoreClick(row.cid, row.cName, row.gender, row.jobJole, formattedDate, row.cPhone, row.city, row.nKW, row.Units, row.ElecType, row.BusinessType, row.SystemType, row.CurrentType, row.InstallationType, row.note, row.empName, row.ClearanceStatus, row.ClearanceDate)} className="admin-location-button">
                          more
                        </button>
                        {loadingSeeMore[row.cid] && <CircularProgress size={24} style={{ marginLeft: '10px' }} />} {/* Show loading indicator for this specific record */}
                      </td>
                      <td>{row.empId}</td>
                      <td>{row.empName}</td>
                      <td>{row.cRate}</td>
                      <td>
                      <button onClick={() => handleClickOpen(row.cid)} className="admin-location-button">
                          Calls
                        </button>
                      <button
                        onClick={() => handleClearanceClick( row.cid, row.cName, row.nKW)}
                        className="admin-location-button">clearance
                      </button>
                      <button
                          onClick={() => deleteUser({ cid: row.cid })} style={{ backgroundColor: 'transparent', color: 'red', border: 'none' }}>
                          <Delete/>
                        </button>
                        {row.pdfStatus === 'yes' ? (
                          <span style={{ color: 'gold', marginLeft: '5px' }}>★</span>
                        ) : (
                          <span style={{ color: '#888', marginLeft: '5px' }}>★</span>
                        )}
                        <span>{row.sendPDF === 'yes' ? 'Send' : 'Not Send'}</span>
                    </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>

          <Dialog open={openNoteDialog} onClose={handleCloseNoteDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
              Special Note
              <IconButton edge="end" color="inherit" onClick={handleCloseNoteDialog} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {selectedNote ? (
                <div>
                  <h4>Special Note:</h4>
                  <p>{selectedNote}</p>
                </div>
              ) : (
                <p>No Note available.</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNoteDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* See More Dialog */}
         <Dialog open={openSeeMoreDialog} onClose={handleCloseSeeMoreDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
              More Details
              <IconButton edge="end" color="inherit" onClick={handleCloseSeeMoreDialog} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {selectedSeeMore ? (
                <div className='seemore-popup'>
                <h4>Client ID: <span>{selectedSeeMore.cid}</span></h4>
                <h4>Client Name: <span>{selectedSeeMore.gender}. {selectedSeeMore.cName}</span></h4>
                <h4>Date: <span>{selectedSeeMore.formattedDate}</span></h4>
                <h4>Client Phone Number: <span>{selectedSeeMore.cPhone}</span></h4>
                <h4>Client City: <span>{selectedSeeMore.city}</span></h4>
                <h4>Client Job Role: <span>{selectedSeeMore.jobJole}</span></h4>
                <h4>Number Of KW: <span>{selectedSeeMore.nKW}</span></h4>
                <h4>Number Of Units: <span>{selectedSeeMore.Units}</span></h4>
                <h4>Electricity Type: <span>{selectedSeeMore.ElecType}</span></h4>
                <h4>Business Type: <span>{selectedSeeMore.BusinessType}</span></h4>
                <h4>System Type: <span>{selectedSeeMore.SystemType}</span></h4>
                <h4>Current Type: <span>{selectedSeeMore.CurrentType}</span></h4>
                <h4>Installation Type: <span>{selectedSeeMore.InstallationType}</span></h4>
                <h4>Special Note: <span>{selectedSeeMore.note}</span></h4>
                <hr></hr>

                <h3>Clearance</h3>

                <h4>Clearance Status: <span>{selectedSeeMore.ClearanceStatus === 'Yes' ? 'Get Clearance' : 'Not given Yet'}</span></h4><br></br>

                <h4>1st Clearance call: <span>{selectedSeeMore.res1.fCallRemark || 'Not yet'}</span></h4>
                <h4>1st Clearance call Date: <span>{new Date(selectedSeeMore.res1.fCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>2nd Clearance call: <span>{selectedSeeMore.res1.sCallRemark || 'Not yet'}</span></h4>
                <h4>2nd Clearance call Date: <span>{new Date(selectedSeeMore.res1.sCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>3rd Clearance call: <span>{selectedSeeMore.res1.tCallRemark || 'Not yet'}</span></h4>
                <h4>3rd Clearance call Date: <span>{new Date(selectedSeeMore.res1.tCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>4th Clearance call: <span>{selectedSeeMore.res1.fourCallRemark || 'Not yet'}</span></h4>
                <h4>4th Clearance call Date: <span>{new Date(selectedSeeMore.res1.fourCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>5th Clearance call: <span>{selectedSeeMore.res1.fiveCallRemark || 'Not yet'}</span></h4>
                <h4>5th Clearance call Date: <span>{new Date(selectedSeeMore.res1.fiveCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>6th Clearance call: <span>{selectedSeeMore.res1.sixCallRemark || 'Not yet'}</span></h4>
                <h4>6th Clearance call Date: <span>{new Date(selectedSeeMore.res1.sixCallDate).toLocaleDateString() || 'Not yet'}</span></h4>

                <hr></hr>

                <h3>Site Visit</h3>

                <h4>Site visit status: <span>{selectedSeeMore.res2.visitGet === 'Yes' ? 'Got a date' : 'No date Yet'}</span></h4><br></br>

                <h4>Site Visited Persons: <span>{selectedSeeMore.res2.visitedPersons || 'Not yet'}</span></h4>
                <h4>Site Visited Date: <span>{new Date(selectedSeeMore.res2.visitedDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>1st Site Visit call: <span>{selectedSeeMore.res2.fVisitCallRemark || 'Not yet'}</span></h4>
                <h4>1st Site Visit call Date: <span>{new Date(selectedSeeMore.res2.fVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>2nd Site Visit call: <span>{selectedSeeMore.res2.sVisitCallRemark || 'Not yet'}</span></h4>
                <h4>2nd Site Visit call Date: <span>{new Date(selectedSeeMore.res2.sVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>3rd Site Visit call: <span>{selectedSeeMore.res2.tVisitCallRemark || 'Not yet'}</span></h4>
                <h4>3rd Site Visit call Date: <span>{new Date(selectedSeeMore.res2.tVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>4th Site Visit call: <span>{selectedSeeMore.res2.fourVisitCallRemark || 'Not yet'}</span></h4>
                <h4>4th Site Visit call Date: <span>{new Date(selectedSeeMore.res2.fourVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>5th Site Visit call: <span>{selectedSeeMore.res2.fiveVisitCallRemark || 'Not yet'}</span></h4>
                <h4>5th Site Visit call Date: <span>{new Date(selectedSeeMore.res2.fiveVisitCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <hr></hr>

                <h3>Advance</h3>
                <h4>Advance Status: <span>{selectedSeeMore.res3.advanceGet === 'Yes' ? 'Take a Advance' : 'Advance not taken'}</span></h4><br></br>
                <h4>1st Advance call: <span>{selectedSeeMore.res3.fAdvanceCallRemark || 'Not yet'}</span></h4>
                <h4>1st Advance call Date: <span>{new Date(selectedSeeMore.res3.fAdvanceCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>2nd Advance call: <span>{selectedSeeMore.res3.sAdvanceCallRemark || 'Not yet'}</span></h4>
                <h4>2nd Advance call Date: <span>{new Date(selectedSeeMore.res3.sAdvanceCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <h4>3rd Advance call: <span>{selectedSeeMore.res3.tAdvanceCallRemark || 'Not yet'}</span></h4>
                <h4>3rd Advance call Date: <span>{new Date(selectedSeeMore.res3.tAdvanceCallDate).toLocaleDateString() || 'Not yet'}</span></h4><br></br>

                <Button onClick={generatePDF} variant="contained" color="primary" className="download-pdf-button">Download PDF</Button>

              </div>              
              ) : (
                <p>No details available.</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSeeMoreDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog open={openClearanceDialog} onClose={handleCloseClearanceDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
              Generate clearance
              <IconButton edge="end" color="inherit" onClick={handleCloseClearanceDialog} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {selectedClearance ? (
                <div style={{ textAlign: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                  {loadingImage ? ( // Show loading circle if loadingImage is true
                    <CircularProgress size={50} />
                  ) : imageUrl ? (
                    <img src={imageUrl} alt="Clearance" style={{ maxWidth: '80%', height: 'auto', borderRadius: '10px' }} />
                  ) : (
                    <p>No Clearance uploaded</p> // Alternative message when imageUrl is empty
                  )}
                  {/* Download Image Button */}
                  <button 
                    onClick={() => window.open(imageUrl, '_blank')} // Open image in a new tab
                    style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', fontSize: '16px', marginTop: '10px' }}
                  >
                    Download Image
                  </button>
                  <h4>Enter Customer Details</h4>

                  <div className="input-group">
                    <label htmlFor="employeekw" className='itemRow'>Customer Name</label>
                    <input 
                      type="text" 
                      id="name" 
                      value={clearanceName}
                      onChange={(e) => setClearanceName(e.target.value)} 
                      placeholder="Enter Customer Name"
                      required
                      className='GetItemDetails1'
                    />
                  </div>
                  
                  <div className="input-group">
                    <label htmlFor="employeekw" className='itemRow'>Customer Address</label>
                    <input 
                      type="text" 
                      id="address" 
                      value={clearanceAddress}
                      onChange={(e) => setClearanceAddress(e.target.value)} 
                      placeholder="Enter Customer Address"
                      required
                      className='GetItemDetails1'
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="employeekw" className='itemRow'>System Capacity</label>
                    <input 
                      type="text" 
                      id="Capacity" 
                      value={clearanceCapacity}
                      onChange={(e) => setClearanceCapacity(e.target.value)} 
                      placeholder="System Capacity"
                      required
                      className='GetItemDetails1'
                    />
                  </div>

                  <Button onClick={() => generateClearance(selectedClearance.cid)} variant="contained" color="primary" className="download-pdf-button">Generate</Button> <br></br>

                  {pdfLink && (
                    <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: '300px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
                      <h4 style={{ color: 'black', fontSize: '18px', marginBottom: '15px' }}>PDF generated! Click below to download:</h4>
                      <a href={pdfLink} download="CEB_Clearance_Document.pdf">
                        <button style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', fontSize: '16px' }}>
                          Download PDF
                        </button>
                      </a>
                    </div>              
                  )}

                </div>
              ) : (
                <p>No Note available.</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClearanceDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select an Action</DialogTitle>
            <DialogContent>
              {/* Additional content can be added here if needed */}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClearanceCall(open)} color="primary">
                Clearance Call
              </Button>
              <Button onClick={() => handleSiteVisitCall(open)} color="primary">
                Site Visit Call
              </Button>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          
        </>
      )}
    </div>
    </Box>

    <div>
        <Foot/>
      </div>
    </div>
  );
};

export default AdminTable;
