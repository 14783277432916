import React, { useState, useEffect } from 'react';
import './ClearanceCalling.css';
import { useParams, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import NaviMain from '../NavigationMain'
import Foot from '../footer';
import Switch from '@mui/material/Switch';
import { storage } from '../firebase'; // Import your Firebase storage configuration
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage'; // Import necessary Firebase functions
import LinearProgress from '@mui/material/LinearProgress'; // Import LinearProgress for loading bar


const ClearanceCalling = () => {
  const { cid} = useParams();


  const [clearanceGetDate, setClearanceGetDate] = useState('');
  const [clearanceGet, setClearanceGet] = useState('');
  const [fCallRemark, setFCallRemark] = useState('');
  const [sCallRemark, setSCallRemark] = useState('');
  const [tCallRemark, setTCallRemark] = useState('');
  const [fourCallRemark, setFourCallRemark] = useState('');
  const [fiveCallRemark, setFiveCallRemark] = useState('');
  const [sixCallRemark, setSixCallRemark] = useState('');

  const [fCallDate, setFCallDate] = useState('');
  const [sCallDate, setSCallDate] = useState('');
  const [tCallDate, setTCallDate] = useState('');
  const [fourCallDate, setFourCallDate] = useState('');
  const [fiveCallDate, setFiveCallDate] = useState('');
  const [sixCallDate, setSixCallDate] = useState('');

  const [isServiceAdded, setIsServiceAdded] = useState({
      fs: false,
      ss: false,
      ts: false,
      fours: false,
      fives: false,
      sixs: false
  });

  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

  const [image, setImage] = useState(null); // State for the uploaded image
  const [imagePreview, setImagePreview] = useState(''); // State for image preview
  const [imageUrl, setImageUrl] = useState(''); // State for the uploaded image URL
  const [loadingImageUpload, setLoadingImageUpload] = useState(false); // State for image upload loading
  const [loadingImageFetch, setLoadingImageFetch] = useState(false); // State for image fetch loading

  const [callTimestamps, setCallTimestamps] = useState({
      fs: null,
      ss: null,
      ts: null,
      fours: null,
      fives: null,
      sixs: null
  });

    const userEmail = sessionStorage.getItem('userEmail');
  
    useEffect(() => {
      if (!userEmail) {
        navigate('/login');
      } else {
        fetchServiceData();
        fetchClientData();
      }
    }, [cid, userEmail, navigate]);

    const fetchServiceData = () => {
        if (cid) {
            Axios.get(process.env.REACT_APP_HOST_IP + `/api/clearance?cid=${cid}`)
                .then((response) => {
                    if (response.data.response) {
                        const { clearanceGetDate, fCallRemark, sCallRemark, tCallRemark, fCallDate, sCallDate, tCallDate, fourCallRemark, fourCallDate, fiveCallRemark, fiveCallDate, sixCallRemark, sixCallDate } = response.data.response;
                        setClearanceGetDate(clearanceGetDate || '');
                        setFCallRemark(fCallRemark || '');
                        setSCallRemark(sCallRemark || '');
                        setTCallRemark(tCallRemark || '');
                        setFourCallRemark(fourCallRemark || '');
                        setFiveCallRemark(fiveCallRemark || '');
                        setSixCallRemark(sixCallRemark || '');

                        setFCallDate(fCallDate || '');
                        setSCallDate(sCallDate || '');
                        setTCallDate(tCallDate || '');
                        setFourCallDate(fourCallDate || '');
                        setFiveCallDate(fiveCallDate || '');
                        setSixCallDate(sixCallDate || '');
                        // Disable button if there's an existing date
                        setIsServiceAdded({
                            fs: !!fCallDate,
                            ss: !!sCallDate,
                            ts: !!tCallDate,
                            fours: !!fourCallDate,
                            fives: !!fiveCallDate,
                            sixs: !!sixCallDate
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching client data:', error);
                });
        }
    };

    const fetchClientData = () => {
      Axios.get(process.env.REACT_APP_HOST_IP + `/api/selectedclient?cid=${cid}`)
                .then((response) => {
                    if (response.data.response) {
                        const { ClearanceStatus } = response.data.response;
                        setClearanceGet(ClearanceStatus || '');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching client data:', error);
                });
    }

    const updateFirstCall = (type) => {
    
      const payload = {
          cid: cid,
          fCallRemark: fCallRemark,
          fCallDate: Date()
      };

      console.log(payload);
  
      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclearance', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              setCallTimestamps(prev => ({ ...prev, [type]: Date.now() })); // Set the timestamp for the call
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
  };

    const updateService = (type) => {
    
        const payload = {
            cid: cid,
            sCallRemark: sCallRemark,
            sCallDate: Date(),
        };

        console.log(payload);
    
        Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclearance', payload)
            .then((response) => {
                setAlert({
                    type: 'success',
                    message: 'Service updated successfully!',
                  });
                setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
                setCallTimestamps(prev => ({ ...prev, [type]: Date.now() })); // Set the timestamp for the call
                fetchServiceData(); // Fetch and update the data after adding service
            })
            .catch((error) => {
                setAlert({
                    type: 'error',
                    message: 'Service added Unsucessfull..! Try again',
                  });
                console.error('Axios Error: ', error);
            });
    };

    const UpdateThirdCall = (type) => {
      const payload = {
          cid: cid,
          tCallRemark: tCallRemark,
          tCallDate: Date()
      };
  
      Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclearance', payload)
          .then((response) => {
              setAlert({
                  type: 'success',
                  message: 'Service updated successfully!',
                });
              setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
              setCallTimestamps(prev => ({ ...prev, [type]: Date.now() })); // Set the timestamp for the call
              fetchServiceData(); // Fetch and update the data after adding service
          })
          .catch((error) => {
              setAlert({
                  type: 'error',
                  message: 'Service added Unsucessfull..! Try again',
                });
              console.error('Axios Error: ', error);
          });
  };

  const UpdateFourthCall = (type) => {
    const payload = {
        cid: cid,
        fourCallRemark: fourCallRemark,
        fourCallDate: Date()
    };

    Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclearance', payload)
        .then((response) => {
            setAlert({
                type: 'success',
                message: 'Service updated successfully!',
              });
            setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
            setCallTimestamps(prev => ({ ...prev, [type]: Date.now() })); // Set the timestamp for the call
            fetchServiceData(); // Fetch and update the data after adding service
        })
        .catch((error) => {
            setAlert({
                type: 'error',
                message: 'Service added Unsucessfull..! Try again',
              });
            console.error('Axios Error: ', error);
        });
};

const UpdateFifthCall = (type) => {
  const payload = {
      cid: cid,
      fiveCallRemark: fiveCallRemark,
      fiveCallDate: Date()
  };

  Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclearance', payload)
      .then((response) => {
          setAlert({
              type: 'success',
              message: 'Service updated successfully!',
            });
          setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
          setCallTimestamps(prev => ({ ...prev, [type]: Date.now() })); // Set the timestamp for the call
          fetchServiceData(); // Fetch and update the data after adding service
      })
      .catch((error) => {
          setAlert({
              type: 'error',
              message: 'Service added Unsucessfull..! Try again',
            });
          console.error('Axios Error: ', error);
      });
};

const UpdateSixthCall = (type) => {
  const payload = {
      cid: cid,
      sixCallRemark: sixCallRemark,
      sixCallDate: Date()
  };

  Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateclearance', payload)
      .then((response) => {
          setAlert({
              type: 'success',
              message: 'Service updated successfully!',
            });
          setIsServiceAdded(prev => ({ ...prev, [type]: true })); // Hide the button after successful add
          setCallTimestamps(prev => ({ ...prev, [type]: Date.now() })); // Set the timestamp for the call
          fetchServiceData(); // Fetch and update the data after adding service
      })
      .catch((error) => {
          setAlert({
              type: 'error',
              message: 'Service added Unsucessfull..! Try again',
            });
          console.error('Axios Error: ', error);
      });
};

    const isSectionReadOnly = (type) => !!isServiceAdded[type];
    const getInputType = (dateValue) => (dateValue ? 'text' : 'date');


    const handleClearanceToggle = async (cid, currentStatus) => {
      const newStatus = currentStatus === 'Yes' ? 'No' : 'Yes';
      const newStatusClient = currentStatus === 'Yes' ? 'No' : 'Yes';
      try {
        // Make an API call to update the sendPDF status
        const payload = {
          cid: cid,
          clearanceGet: newStatus,
          clearanceGetDate: Date()
        }
        const payloadClient = {
          cid: cid,
          ClearanceStatus: newStatusClient,
        }
        console.log(payload)
        await Axios.post(`${process.env.REACT_APP_HOST_IP}/api/updateclearance`,payload);
        await Axios.post(`${process.env.REACT_APP_HOST_IP}/api/updateclient`,payloadClient);
  
        // Update the local state
        setClearanceGet(newStatus);
      } catch (error) {
        console.error('Error updating sendPDF status:', error);
        // Optionally, show an error message to the user
      }
    };

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setImage(file);
        setImagePreview(URL.createObjectURL(file)); // Create a preview URL
      }
    };

    const handleImageUpload = async () => {
      if (!image) return;

      setLoadingImageUpload(true); // Start loading
      const imageRef = ref(storage, `clearance_images/${cid}/${cid}_${image.name}`); // Rename image with cid
      try {
        await uploadBytes(imageRef, image); // Upload the image
        const url = await getDownloadURL(imageRef); // Get the download URL
        setImageUrl(url); // Set the image URL state
        setAlert({
          type: 'success',
          message: 'Image uploaded successfully!',
        });
        fetchImage(); // Fetch the image after upload
      } catch (error) {
        setAlert({
          type: 'error',
          message: 'Image upload failed. Try again.',
        });
        console.error('Error uploading image:', error);
      } finally {
        setLoadingImageUpload(false); // End loading
      }
    };

    const fetchImage = async () => {
      setLoadingImageFetch(true); // Start loading
      try {
        const imagesRef = ref(storage, `clearance_images/${cid}/`);
        const imageList = await listAll(imagesRef); // List all images in the directory
        const matchingImage = imageList.items.find(item => item.name.startsWith(cid)); // Find the first image with the cid prefix

        if (matchingImage) {
          const url = await getDownloadURL(matchingImage); // Get the download URL
          setImageUrl(url); // Set the image URL state
        } else {
          console.error('No image found with the specified prefix.');
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      } finally {
        setLoadingImageFetch(false); // End loading
      }
    };

    // Call fetchImage in useEffect to load the image when the component mounts
    useEffect(() => {
      if (cid) {
        fetchImage(); // Fetch the image when the component mounts
      }
    }, [cid]);


    const isEditable = (callDate) => {
      return !callDate || (Date.now() - new Date(callDate).getTime() < 24 * 60 * 60 * 1000); // Check if call date is less than 24 hours old
    };

    return (
      <div class="container">
          <div class="menu-body">
              <NaviMain />
          </div>
      
      <div className="service-form-container">
          <h2>Clearance Calling</h2>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Switch
              checked={clearanceGet === 'Yes'}
              onChange={() => handleClearanceToggle(cid, clearanceGet)}
              color="primary"
            />
            <span style={{ marginLeft: '10px' }}>
              {clearanceGet === 'Yes' ? 'Clearance Obtained' : 'Clearance Pending'}
            </span>
          </div>

          {/* Add this new section to display the clearance get date */}
          {clearanceGetDate && (
            <div style={{ marginBottom: '20px' }}>
              <strong>Clearance Get Date:</strong> {new Date(clearanceGetDate).toLocaleString()}
            </div>
          )}

          <form>
              {/* Client ID Section */}
              <div className="form-section">
                  <label htmlFor="clientId">Client ID</label>
                  <input 
                      type="text" 
                      id="clientId" 
                      name="clientId" 
                      className="input-field" 
                      value={cid}
                      readOnly 
                  />
              </div>

              {/* First Service Section */}
              <div className={`form-section ${isSectionReadOnly('fs') ? 'read-only' : ''}`}>
                  <h3>First Clearance Call</h3>
                  
                  <label htmlFor="firstServiceRemark">1st Call Remark</label>
                  <textarea 
                      id="firstServiceRemark" 
                      name="firstServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={fCallRemark}
                      onChange={(e) => setFCallRemark(e.target.value)}
                      readOnly={!isEditable(fCallDate)}
                  ></textarea>
                  {fCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(fCallDate).toLocaleString()}
                    </div>
                  )}
                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => updateFirstCall('fs')}
                      disabled={!isEditable(fCallDate)}
                  >
                      Add 1st Call
                  </button>
              </div>



              {/* Second Service Section */}
              <div className={`form-section ${isSectionReadOnly('ss') ? 'read-only' : ''}`}>
                  <h3>Second Clearance Call</h3>
                  
                  <label htmlFor="secondServiceRemark">2nd Call Remark</label>
                  <textarea 
                      id="secondServiceRemark" 
                      name="secondServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={sCallRemark}
                      onChange={(e) => setSCallRemark(e.target.value)}
                      readOnly={!isEditable(sCallDate)}
                  ></textarea>

                  {sCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(sCallDate).toLocaleString()}
                    </div>
                  )}
                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => updateService('ss')}
                      disabled={!isEditable(sCallDate)}
                  >
                      Add 2nd Call  
                  </button>
              </div>


              {/* Third Service Section */}
              <div className={`form-section ${isSectionReadOnly('ts') ? 'read-only' : ''}`}>
                  <h3>Third Clearance Call</h3>

                  <label htmlFor="thirdServiceRemark">3rd Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={tCallRemark}
                      onChange={(e) => setTCallRemark(e.target.value)}
                      readOnly={!isEditable(tCallDate)}
                  ></textarea>
                  {tCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(tCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateThirdCall('ts')}
                      disabled={!isEditable(tCallDate)}
                  >
                      Add 3rd Call
                  </button>
              </div>




              {/* Forth Service Section */}
              <div className={`form-section ${isSectionReadOnly('fours') ? 'read-only' : ''}`}>
                  <h3>Fourth Clearance Call</h3>

                  <label htmlFor="thirdServiceRemark">4th Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={fourCallRemark}
                      onChange={(e) => setFourCallRemark(e.target.value)}
                      readOnly={!isEditable(fourCallDate)}
                  ></textarea>
                  {fourCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(fourCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateFourthCall('fours')}
                      disabled={!isEditable(fourCallDate)}
                  >
                      Add 4th Call
                  </button>
              </div>



              {/* Fifth Service Section */}
              <div className={`form-section ${isSectionReadOnly('fives') ? 'read-only' : ''}`}>
                  <h3>Fifth Clearance Call</h3>

                  <label htmlFor="thirdServiceRemark">5th Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={fiveCallRemark}
                      onChange={(e) => setFiveCallRemark(e.target.value)}
                      readOnly={!isEditable(fiveCallDate)}
                  ></textarea>
                  {fiveCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(fiveCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateFifthCall('fives')}
                      disabled={!isEditable(fiveCallDate)}
                  >
                      Add 5th Call
                  </button>
              </div>




              {/* Sixth Service Section */}
              <div className={`form-section ${isSectionReadOnly('sixs') ? 'read-only' : ''}`}>
                  <h3>Sixth Clearance Call</h3>

                  <label htmlFor="thirdServiceRemark">6th Call Remark</label>
                  <textarea 
                      id="thirdServiceRemark" 
                      name="thirdServiceRemark" 
                      className="textarea-field" 
                      placeholder="Enter remark"
                      value={sixCallRemark}
                      onChange={(e) => setSixCallRemark(e.target.value)}
                      readOnly={!isEditable(sixCallDate)}
                  ></textarea>
                  {sixCallDate && (
                    <div className="call-date">
                      <strong>Call Date:</strong> {new Date(sixCallDate).toLocaleString()}
                    </div>
                  )}

                  <button 
                      type="button" 
                      className="add-button" 
                      onClick={() => UpdateSixthCall('sixs')}
                      disabled={!isEditable(sixCallDate)}
                  >
                      Add 6th Call
                  </button>
              </div>

              {/* Image Upload Section */}
              <div className="form-section">
                <h3>Upload Clearance Image</h3>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                
                <button
                  type="button"
                  className="add-button"
                  onClick={handleImageUpload}
                  disabled={!image}
                >
                  Upload Image
                </button>
                 {/* Loading bar for image upload */}
          {loadingImageUpload && <LinearProgress />}

          {/* Loading cycle for fetching image */}
          {loadingImageFetch && <LinearProgress />}
                {imagePreview && (
                  <div>
                    <h4>Image Preview:</h4>
                    <img src={imagePreview} alt="Preview" style={{ width: '200px', height: 'auto' }} />
                  </div>
                )}
                {imageUrl && (
                  <div>
                    <h4>Uploaded Image:</h4>
                    <img src={imageUrl} alt="Uploaded" style={{ width: '200px', height: 'auto' }} /> <br></br>
                    <a href={imageUrl} target="_blank" rel="noopener noreferrer">Download Image</a>
                  </div>
                )}
              </div>

          </form>
      </div>

      {alert && (
          <div className={`alert-box ${alert.type}`}>
            {alert.message}
            <button onClick={() => setAlert(null)} className="alert-dismiss-button">OK</button>
          </div>
        )}

      <div>
      <Foot/>
    </div>
      </div>
  );
};

export default ClearanceCalling;
