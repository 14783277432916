import React, { useState, useEffect } from 'react';
import './CSS/ProfileDisplay.css';
import { Paper, Button, TableContainer, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, MenuItem, Select } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Box } from '@mui/material';
import NaviMain from '../AdminNavi';
import './CSS/navi.css';
import './CSS/ServiceHome.css';
import Foot from '../footer';
import { useNavigate } from 'react-router-dom';

const AllRecords = () => {
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedNote, setSelectedNote] = useState(null);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingcy, setLoadingcy] = useState(true);
  const [employees, setEmployees] = useState([]); // New state for employees
  const [selectedEmployee, setSelectedEmployee] = useState({}); // State to track selected employee for each row
  const navigate = useNavigate();

  const userEmail = sessionStorage.getItem('userEmail');
  const password = sessionStorage.getItem('password');

  useEffect(() => {
    if (!userEmail || !password) {
      navigate('/login');
    } else {
      fetchData();
      fetchEmployees(); // Fetch employees data
    }
  }, [userEmail, navigate]);

  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_HOST_IP + '/api/records');
      setRows(response.data.response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingcy(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('http://localhost:3001/api/employees');
      console.log('Employee API Response:', response.data.response); // Check the structure of the response
  
      // Filter the response to include only employees with position 'emp'
      const filteredEmployees = response.data.response.filter(employee => employee.position === 'emp');
      
      setEmployees(filteredEmployees); // Set only filtered employees
      console.log('Filtered Employees:', filteredEmployees);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setEmployees([]); // Set an empty array in case of error
    }
  };
  
  

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  const clearDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const deleteUser = (rid) => {
    const confirmDelete = window.confirm("Do you want to delete this Employee?");
    if (confirmDelete) {
      axios.post(process.env.REACT_APP_HOST_IP + '/api/deleteclient', rid)
        .then((response) => {
          fetchData();
        })
        .catch((error) => {
          console.error('Axios Error: ', error);
        });
    }
  };

  const generateAccessToken = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_HOST_IP + '/api/generate-token'); // Update with your backend API endpoint
      return response.data.accessToken;
    } catch (error) {
      console.error('Error generating access token:', error);
      throw error;
    }
  };

  const generateCusAccessToken = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_HOST_IP + '/api/generate-token'); // Update with your backend API endpoint
      return response.data.accessToken;
    } catch (error) {
      console.error('Error generating access token:', error);
      throw error;
    }
  };

  const updateEmployeeForRecord = (recordId, rPhone) => {
    const selectedEmp = selectedEmployee[recordId]; // Get selected employee for this record
    if (!selectedEmp) return;

    const payload = {
      rid: recordId,
      rempId: selectedEmp.eId,
      rempName: selectedEmp.eName,
    };

    axios.post('http://localhost:3001/api/updaterecord', payload)
      .then((response) => {
        fetchData(); // Fetch updated data
      })
      .catch((error) => {
        console.error('Error updating record:', error);
      });

      const message = `You have new order from the Facebook Ad. please check System..`;
      const messageCus = `Thank you for reaching archer Prime (Pvt)Ltd.
Our Marketing officer ${selectedEmp.eName} You will contact will be soon`;

      SendSMS(selectedEmp.ePhone,message);
      SendSMSCus(rPhone,messageCus);

      console.log(selectedEmp.ePhone, message);
  };


  const SendSMS = async (ePhone, message) => {
    const accessToken = await generateAccessToken();

    const smsPayload = {
      to: ePhone, 
      message: message,
    };

    const sendResponse = await axios.post(
      process.env.REACT_APP_HOST_IP + '/api/send-sms', // Update with your backend API endpoint for sending SMS
      smsPayload,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Pass the access token
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('SMS send response:', sendResponse.data);

  }


  const SendSMSCus = async (rPhone, messageCus) => {
    console.log('Customer message',rPhone, messageCus);

    const accessToken = await generateCusAccessToken();

    const smsCusPayload = {
      to: rPhone, 
      message: messageCus,
    };

    const sendResponse = await axios.post(
      process.env.REACT_APP_HOST_IP + '/api/send-sms', // Update with your backend API endpoint for sending SMS
      smsCusPayload,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Pass the access token
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('SMS send response:', sendResponse.data);

  }



  const filteredRows = Array.isArray(rows) && (selectedCategory === 'All' ? rows : rows.filter(row => row.rElecType === selectedCategory));
  const visibleRows = Array.isArray(filteredRows) && filteredRows.filter(row => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const searchTerm2LowerCase = searchTerm2.toLowerCase();

    const matchesSearchTerm = row.rName.toLowerCase().includes(searchTermLowerCase) 
      || row.rid.toString().includes(searchTermLowerCase) 
      || row.rCity.toLowerCase().includes(searchTermLowerCase);

    const empIdString = row.rempId ? row.rempId.toString().toLowerCase() : '';
    const empNameString = row.rempName ? row.rempName.toLowerCase() : '';
    const matchesSearchTerm2 = empIdString.includes(searchTerm2LowerCase) 
      || empNameString.includes(searchTerm2LowerCase);

    const rowDate = new Date(row.rDate);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const matchesDateRange = (!start || rowDate >= start) && (!end || rowDate <= end);

    return matchesSearchTerm && matchesSearchTerm2 && matchesDateRange;
  }).sort((a, b) => b.rid - a.rid);

  return (
    <div class="container-2">
      <div class="menu-body">
        <NaviMain />
      </div>

      <Box sx={{ margin: 'auto', marginTop: '40px' }}>
        <div className="admin-display-container">
          {loadingcy ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress size={150} />
            </div>
          ) : (
            <>
             <div className='top-actions'>
                <div className="date-filter-group5">
                  <div className="date-filter-ind">
                    <label htmlFor="start-date">Start Date</label>
                    <input
                      type="date"
                      id="start-date"
                      className="styled-date-input5"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  <div className="date-filter-ind">
                    <label htmlFor="end-date">End Date</label>
                    <input
                      type="date"
                      id="end-date"
                      className="styled-date-input5"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <button onClick={clearDateFilters} variant="outlined" color="secondary" class="clear-btn">Clear Dates</button>
                </div>

                <input
                  type="text"
                  className="admin-search-input"
                  placeholder="Search by Client ID or Name or City..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <input
                  type="text"
                  className="admin-search-input-emp"
                  placeholder="Search by Employee ID or Name..."
                  value={searchTerm2}
                  onChange={(e) => setSearchTerm2(e.target.value)}
                />
              </div>

              <div className="admin-button-group">
                <button onClick={() => setSelectedCategory('All')} className="admin-button">All Types</button>
                <button onClick={() => setSelectedCategory('CEB')} className="admin-button">CEB</button>
                <button onClick={() => setSelectedCategory('LECO')} className="admin-button">LECO</button>
              </div>

              <TableContainer component={Paper}>
                <table className="admin-table">
                  <thead>
                    <tr>
                      <th>Record ID</th>
                      <th>Client Name</th>
                      <th>Date</th>
                      <th>Phone Number</th>
                      <th>Electricity Type</th>
                      <th>City</th>
                      <th>Special Note</th>
                      <th>Selected Employee</th>
                      <th>Employee Selection</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(visibleRows) && visibleRows.map((row) => {
                      const formattedDate = new Date(row.rDate).toLocaleDateString();
                      return (
                        <tr key={row.rid}>
                          <td>{row.rid}</td>
                          <td>{row.rName}</td>
                          <td>{formattedDate}</td>
                          <td>{row.rPhone}</td>
                          <td>{row.rElecType}</td>
                          <td>{row.rCity}</td>
                          <td><button onClick={() => handleNoteClick(row.rnote)} className="admin-location-button">View</button></td>
                          <td>
                            {row.rempId ? (
                              <>
                                {row.rempId} - {row.rempName}
                              </>
                            ) : (
                              <span style={{ color: 'red' }}>No Employee Assigned</span>
                            )}
                          </td>

                          <td>
                            <Select
                              value={selectedEmployee[row.rid] || ''}
                              onChange={(e) => setSelectedEmployee({ ...selectedEmployee, [row.rid]: e.target.value })}
                              displayEmpty
                              sx={{
                                width: '150px', // Set a custom width
                                fontSize: '12px', // Reduce the font size
                                padding: '5px', // Adjust the padding
                                height: '40px', // Optionally adjust the height
                              }}
                              disabled={!!row.rempId}  // Disable if rempId is not empty
                            >
                              <MenuItem value="">Select Employee</MenuItem>
                              {employees.map((emp) => (
                                <MenuItem key={emp.eId} value={emp}>
                                  {emp.eId} - {emp.eName}
                                </MenuItem>
                              ))}
                            </Select>

                            <button
                              onClick={() => updateEmployeeForRecord(row.rid, row.rPhone)}
                              className="admin-add-employee-button"
                              disabled={!selectedEmployee[row.rid]}
                            >
                              Add Employee
                            </button>
                          </td>

                          <td>
                            <button
                              onClick={() => deleteUser({ rid: row.rid })}
                              className="admin-delete-button">Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </TableContainer>

              <Dialog open={openNoteDialog} onClose={handleCloseNoteDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                  Special Note
                  <IconButton edge="end" color="inherit" onClick={handleCloseNoteDialog} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {selectedNote ? (
                    <div>
                      <h4>Special Note:</h4>
                      <p>{selectedNote}</p>
                    </div>
                  ) : (
                    <p>No Note available.</p>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseNoteDialog} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

              <Foot />
            </>
          )}
        </div>
      </Box>
    </div>
  );
};

export default AllRecords;
