import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import UserForm from './adminHome';
import AdminDisplay from './ProfileDisplay';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Foot from '../footer';
import './CSS/navi.css';
import NaviMain from '../AdminNavi';
import './CSS/ServiceHome.css';

const UserProfile = () => {
  // Create useStates
  const [users, setUsers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const navigate = useNavigate()
  const [loadingcy, setLoadingcy] = useState(true);

  const userEmail = sessionStorage.getItem('userEmail');
  const password = sessionStorage.getItem('password');

  useEffect(() => {
    if (!userEmail || !password) {
      navigate('/login');
    } else {
      getUsers();
    }
  }, [userEmail, navigate]);

  //Getting Clients using url
  const getUsers = () => {
      const getAdminDetails = () => {
        Axios.get(process.env.REACT_APP_HOST_IP + '/api/employees')
          .then((response) => {
            const filteredData = response.data?.response.filter(user => user.position === 'emp') || [];
            setUsers(filteredData);
            setLoadingcy(false); 
          })
          .catch((error) => {
            console.error('Axios Error: ', error);
          });
      };
      getAdminDetails();
      const intervalId = setInterval(getAdminDetails, 1000);  //Update Getting Details every 1 second
      return () => clearInterval(intervalId);
  };

  // Add Client in the CRM system
  const addUser = (data) => {
    setSubmitted(true);
    setIsEdit(false);

    // create Payload for the create new Client
    const payload = {
        eId: data.eId,
        eName: data.eName,
        eEmail: data.eEmail,
        ePhone: data.ePhone,
        ePassword: data.ePassword,
        totRecords: 0,
        totRates: 0,
        position: 'emp',
    };

    // Create new Client using url
    Axios.post(process.env.REACT_APP_HOST_IP + '/api/createemployee', payload)
      .then((response) => {
        alert("Sucessfully added Employee..!")
        getUsers();
        setSubmitted(false);
        setDisplayTable(true);
      })
      .catch((error) => {
        console.error('Axios Error: ', error);
      });
  };


  // Update client details
  const updateUser = (data) => {
    setSubmitted(true);
    setIsEdit(false);

    // create Payload for the Update Client details
    const payload = {
      eId: data.eId,
      eName: data.eName,
      eEmail: data.eEmail,
      ePhone: data.ePhone,
      ePassword: data.ePassword,
  };

    // Update existing client using this url
    Axios.post(process.env.REACT_APP_HOST_IP + '/api/updateemployee', payload)
      .then((response) => {
        getUsers();
        setSubmitted(false);
        setDisplayTable(true);
      })
      .catch((error) => {
        console.error('Axios Error: ', error);
      });
  };

  // Delete existing client using this url
  const deleteUser = (data) => {
    alert("Do you want to delete this Employee ..!")
    Axios.post(process.env.REACT_APP_HOST_IP + '/api/deleteemployee', data)
      .then((response) => {
        getUsers();
      })
      .catch((error) => {
        console.error('Axios Error: ', error);
      });
  };

  // Display all client button set
  const handleDisplayButtonClick = () => {
    setDisplayTable(true);
    setSelectedUser({});
    setIsEdit(false);
  };
  // Start return
  return (
    <div class="container">

{/* Product Manager Navigation bar */}
    <div class="menu-body">
      <NaviMain />
      <nav>
        <ul className="nav-bar-5">
          <span className="menu">
            <li><a href="/profile">Add Employee</a></li>
            <li><a href="#" onClick={handleDisplayButtonClick}>Employee Details</a></li>
          </span>
          <label htmlFor="check" className="open-menu"><i className="fas fa-bars"></i></label>
        </ul>
      </nav>
    </div>
      
    <Box sx={{ margin: 'auto', marginTop: '30px' }}>
      
      {displayTable ? (
        <AdminDisplay  // Call all product items display page
          rows={users} //Pass all product details getting in the api
          selectedUser={(user) => {  
            setSelectedUser(user);
            setIsEdit(true);
            setDisplayTable(false);
          }}
          deleteUser={deleteUser}
          loadingcy={loadingcy}
        />
      ) : (
        <div>
          <UserForm  // Call all Add or Update items form page
            addUser={addUser}
            updateUser={updateUser}
            submitted={submitted}
            data={selectedUser}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </div>
      )}
    </Box>


{/* Call footer */}
    <div>
        <Foot/>
      </div>
    </div>
  );
};

export default UserProfile;
