import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Function to generate access token
const generateAccessToken = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_HOST_IP + '/api/generate-token'); // Update with your backend API endpoint
    return response.data.accessToken;
  } catch (error) {
    console.error('Error generating access token:', error);
    throw error;
  }
};

// Function to send SMS
const SendSMS = async (cid, EmpID, cPhone) => {
  try {
    // Base URL for the feedback form or system
    const baseURL = process.env.REACT_APP_HOST_IP_FRONT + '/ReviewPage'; // Ensure REACT_APP_HOST_IP_FRONT is defined in .env
    const encodedId = uuidv4();

    // Create the unique feedback link with query parameters, ensuring proper encoding
    const feedbackLink = `${baseURL}/${cid}/${encodedId}/${EmpID}`;
    const message = `Dear valued customer,

Thank you for reaching out to ArcherPrime International (Pvt) Ltd. 
We greatly value your feedback! Please take a moment to share your experience with us by clicking the link below: ${feedbackLink}
    
`;

    // Generate access token
    const accessToken = await generateAccessToken();
   // console.log('Generated Access Token:', accessToken);

    // Prepare the payload for sending SMS
    const smsPayload = {
      to: cPhone, // Customer's phone number
      message: message, // Feedback message
    };

    // Send the SMS
    const sendResponse = await axios.post(
      process.env.REACT_APP_HOST_IP + '/api/send-sms', // Update with your backend API endpoint for sending SMS
      smsPayload,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Pass the access token
          'Content-Type': 'application/json',
        },
      }
    );

    //console.log('SMS send response:', sendResponse.data);

  } catch (error) {
    console.error('Failed to send SMS:', error.response ? error.response.data.message : error.message);
  }
};

export default SendSMS;
