import React, { useState } from 'react';
import axios from 'axios';

function App() {
    const [clientName, setClientName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [location, setLocation] = useState();
    const [otherData, setOtherData] = useState();

  const [pdfLink, setPdfLink] = useState('');

  // Submit form data and generate PDF
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
        clientName,
        projectName,
        location,
        otherData
    };

    try {
      // Ensure responseType is 'blob' to correctly handle binary data (PDF)
      const response = await axios.post('http://localhost:3001/api/generate-pdf', payload, {
        responseType: 'blob' // Important: Handle binary data
      });

      // Create a link to the PDF
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setPdfLink(fileURL);

    } catch (error) {
      console.error('Error generating PDF:', error);
    }
};


  return (
    <div className="App">
      <h1>Solar Panel System PDF Generator</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Client Name:</label>
          <input
            type="text"
            name="clientName"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Project Name:</label>
          <input
            type="text"
            name="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Location:</label>
          <input
            type="text"
            name="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Other Data:</label>
          <input
            type="text"
            name="otherData"
            value={otherData}
            onChange={(e) => setOtherData(e.target.value)}
            required
          />
        </div>

        <button type="submit">Generate PDF</button>
      </form>

      {pdfLink && (
        <div>
          <h3>PDF generated! Click below to download:</h3>
          <a href={pdfLink} download="generated_document.pdf">
            <button>Download PDF</button>
          </a>
        </div>
      )}
    </div>
  );
}

export default App;
