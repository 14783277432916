import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import './CSS/login.css';
import { LinearProgress } from "@mui/material";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [empemail, setEmpemail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLoginButtonClick = (e) => {
    e.preventDefault(); // Prevent the page from refreshing

    setLoading(true);
    //console.log('ip',process.env.REACT_APP_HOST_IP)

    Axios.get(process.env.REACT_APP_HOST_IP + '/api/employees')
      .then((response) => {
        const users = response.data.response;

        const user = users.find((u) => u.eEmail === empemail);

        if (user) {
          if (user.ePassword === password) {


            if(user.position === 'admin'){
              // Save admin email and position in localStorage
              sessionStorage.setItem('userEmail', empemail);
              sessionStorage.setItem('password', password);
              sessionStorage.setItem('AdminName', user.eName);
              sessionStorage.setItem('branch', user.branch);
              navigate('/BranchAdmin');
            }
            else if(user.position === 'MainAdmin'){
              sessionStorage.setItem('userEmail', empemail);
              sessionStorage.setItem('password', password);
              sessionStorage.setItem('AdminName', user.eName);
              navigate('/ClientAdmin');
            }
            else{
              if(user.position === 'emp'){
                sessionStorage.setItem('userEmail', empemail);
                sessionStorage.setItem('EmpID', user.eId);
                sessionStorage.setItem('EmpName', user.eName);
                navigate('/addclient');
              }
              else{
                alert('INCORRECT POSITION');
              }
            }  
          } else {
            alert('Incorrect password');
          }
        } else {
          
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Failed to fetch users:', error);
      });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleLoginButtonClick}>

          <div className="input-group">
            <label>Email Address</label>
            <input 
              type="email" 
              placeholder="Enter your email"
              id="empemail" 
              name="empemail"
              value={empemail} 
              onChange={(e) => setEmpemail(e.target.value)} 
              required 
            />
          </div>

          <div className="input-group password-group">
            <label>Password</label>

            <div className="password-wrapper">
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Enter your password"
                id="password" 
                name="password"
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required
              />

              <span className="eye-icon" onClick={togglePasswordVisibility}>
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <button type="submit" className="login-button">Login</button>

          <div style={{ color: "#000000", marginTop:"20px" }}>
                {loading && <LinearProgress />}
            </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
