import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CSS/EmployeeRecords.css';
import Navi from '../NavigationMain';
import Foot from '../footer';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EmployeeRecords = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [filter, setFilter] = useState('All'); // State for filtering records
  const navigate = useNavigate();
  const EmpID = sessionStorage.getItem('EmpID');

  useEffect(() => {
    const userEmail = sessionStorage.getItem('userEmail');
    if (!userEmail) {
      navigate('/login');
    } else {
      fetchRecords();
    }
  }, [navigate]);

  const fetchRecords = () => {
    Axios.get(`${process.env.REACT_APP_HOST_IP}/api/selectedrecordemp?rempId=${EmpID}`)
      .then((response) => {
        setRecords(response.data?.response || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching records:', error);
        setLoading(false);
      });
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
    setSelectedNote(null); // Clear selected note when closing
  };

  const handleStatusChange = (recordId, currentStatus) => {
    const newStatus = currentStatus === 'pending' ? 'complete' : 'pending';
    console.log(recordId, currentStatus, newStatus);

    const payload = {
      rid: recordId,
      rstatus: newStatus
    };

    Axios.post(process.env.REACT_APP_HOST_IP + '/api/updaterecord', payload)
      .then(() => {
        // Fetch updated records after status change
        fetchRecords();
      })
      .catch((error) => {
        console.error('Error updating status:', error);
      });
  };

  // Function to filter records based on selected status
  const filteredRecords = records.filter(record => {
    if (filter === 'All') return true; // Show all records
    return record.rstatus === filter.toLowerCase(); // Filter by status
  });

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress size={150} />
        </div>
      ) : (
        <>
          <Navi />
          <div className="employee-records-container">
            <div className="employee-records-paper">
              <h1 className="employee-records-title">Facebook Ad Records</h1>

              {/* Filter Buttons */}
              <div className="filter-buttons">
                <Button variant="contained" onClick={() => setFilter('All')}>All</Button>
                <Button variant="contained" onClick={() => setFilter('Pending')}>Pending</Button>
                <Button variant="contained" onClick={() => setFilter('Complete')}>Complete</Button>
              </div>

              <div className="employee-records-table-container">
                <table className="employee-records-table">
                  <thead>
                    <tr>
                      <th>Record ID</th>
                      <th>Record Name</th>
                      <th>Date</th>
                      <th>Phone</th>
                      <th>Electricity Type</th>
                      <th>Notes</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRecords.length > 0 ? (
                      filteredRecords.map((record, index) => (
                        <tr key={index}>
                          <td>{record.rid}</td>
                          <td>{record.rName}</td>
                          <td>{new Date(record.rDate).toLocaleDateString()}</td>
                          <td>{record.rPhone}</td>
                          <td>{record.rElecType}</td>
                          <td>
                            <button className="view-note-button" onClick={() => handleNoteClick(record.rnote)}>
                              View Note
                            </button>
                          </td>
                          <td>
                            <div>
                              <button
                                className={`status-button ${record.rstatus === 'pending' ? 'highlight' : ''}`}
                                onClick={() => handleStatusChange(record.rid, record.rstatus)}
                              >
                                Pending
                              </button>
                              <button
                                className={`status-button2 ${record.rstatus === 'complete' ? 'highlight2' : ''}`}
                                onClick={() => handleStatusChange(record.rid, record.rstatus)}
                              >
                                Complete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7} align="center">No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Foot />

          <Dialog open={openNoteDialog} onClose={handleCloseNoteDialog} maxWidth="sm" fullWidth>
            <DialogTitle className="employee-records-dialog-title">
              Special Note
              <IconButton edge="end" color="inherit" onClick={handleCloseNoteDialog} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="employee-records-dialog-content">
              {selectedNote ? (
                <div>
                  <h4>Special Note:</h4>
                  <p>{selectedNote}</p>
                </div>
              ) : (
                <p>No Note available.</p>
              )}
            </DialogContent>
            <DialogActions className="employee-records-dialog-actions">
              <Button onClick={handleCloseNoteDialog} color="primary">Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default EmployeeRecords;
